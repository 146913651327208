<template>
 <div class="bg-white shadow-md rounded-lg relative pb-4">
  <h2 class="text-center text-sm -mb-4 font-bold">Appels de moins de 10 sec</h2>
  <div class="lg:flex justify-center items-center text-center">
   <div id="chart">
    <apexchart
     type="radialBar"
     height="350"
     :options="chartOptions"
     :series="series"></apexchart>
   </div>
   <div class="items-center lg:text-left sm:text-center -ml-4">
    <!-- <div class="text-xs">
     <span>Appels de moins de 10 sec</span>
    </div> -->
    <div class="text-sm font-bold text-center">
     <span class="text-blue-600">{{ talking_10 }}</span>
    </div>
    <div class="text-sm font-bold text-center">
     <span class="text-blue-600 text-center">appels &lt; 10 sec</span>
     <!-- /<span class="text-blue-600">{{ total }}</span> -->
    </div>
    
    <div class="text-sm font-bold text-center">
     <span class="text-gray-500 font-bold text-xs"
      >(sur {{ total }} appels)</span
     >
    </div>
    <!-- <div class="lg:flex sm:flex text-lg font-bold">
     <span class="text-blue-600">{{ talking_10 }}</span
     >/<span class="text-blue-600">{{ total }}</span>
    </div> -->
   </div>
  </div>
  <div
   class="flex justify-center items-center px-2 py-2 text-sm bg-blue-100 rounded-lg shadow-md text-blue-800 mx-2 h-1/4">
   <div>
    <span class="font-bold">
     {{ ((talking_10 / total) * 100).toFixed(0) }}%
    </span>
    des appels ont duré moins de 10 secondes et correspondent souvent à des
    erreurs d'appel ou à des appels transférés.
   </div>
  </div>
 </div>
</template>

<script>
export default {
 props: ["total", "talking_10"],
 data() {
  return {
   series: [
    ((parseInt(this.talking_10) / parseInt(this.total)) * 100).toFixed(0),
   ],
   chartOptions: {
    chart: {
     fontFamily: "Nunito, sans-serif",
     type: "radialBar",
     offsetY: 0,
    },
    plotOptions: {
     radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
       background: "#e7e7e7",
       strokeWidth: "97%",
       margin: 5, // margin is in pixels
       dropShadow: {
        enabled: true,
        top: 2,
        left: 0,
        color: "#999",
        opacity: 1,
        blur: 2,
       },
      },
      dataLabels: {
       value: {
        offsetY: -2,
        fontFamily: "Nunito, sans-serif",
        fontSize: "20px",
        color: "#0000FF",
        formatter: function (val) {
         return val + "%";
        },
       },
      },
     },
    },
    fill: {
     type: "gradient",
     gradient: {
      shade: "dark",
      shadeIntensity: 0.15,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 65, 91],
     },
    },
    stroke: {
     dashArray: 6,
    },
    labels: [""],
   },
  };
 },
 methods: {},
 mounted() {
  this.$emit("setLoading", false);
 },
 updated() {
  this.$emit("setLoading", false);
 },
};
</script>

<style></style>
