<template>
 <div>
  <apexchart
   type="line"
   :options="chartOptions"
   :series="series"
   height="350" />
 </div>
</template>

<script>
export default {
 props: ["dataSeries", "labels"],
 components: {},
 data() {
  return {
   series: this.dataSeries,
   chartOptions: {
    chart: {
     defaultLocale: "fr",
     locales: [
      {
       name: "fr",
       options: {
        months: [
         "Janvier",
         "Février",
         "Mars",
         "Avril",
         "Mai",
         "Juin",
         "Juillet",
         "Août",
         "Septembre",
         "Octobre",
         "Novembre",
         "Décembre",
        ],
        shortMonths: [
         "Janv",
         "Févr",
         "Mars",
         "Avr",
         "Mai",
         "Juin",
         "Juil",
         "Août",
         "Sept",
         "Oct",
         "Nov",
         "Déc",
        ],
        days: [
         "Dimanche",
         "Lundi",
         "Mardi",
         "Mercredi",
         "Jeudi",
         "Vendredi",
         "Samedi",
        ],
        shortDays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        toolbar: {
         download: "Télécharger SVG",
         selection: "Sélection",
         selectionZoom: "Zoom de sélection",
         zoomIn: "Zoom avant",
         zoomOut: "Zoom arrière",
         pan: "Panoramique",
         reset: "Réinitialiser le zoom",
        },
       },
      },
      {
       name: "en",
       options: {
        months: [
         "January",
         "February",
         "March",
         "April",
         "May",
         "June",
         "July",
         "August",
         "September",
         "October",
         "November",
         "December",
        ],
        shortMonths: [
         "Jan",
         "Feb",
         "Mar",
         "Apr",
         "May",
         "Jun",
         "Jul",
         "Aug",
         "Sep",
         "Oct",
         "Nov",
         "Dec",
        ],
        days: [
         "Sunday",
         "Monday",
         "Tuesday",
         "Wednesday",
         "Thursday",
         "Friday",
         "Saturday",
        ],
        shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        toolbar: {
         download: "Download SVG",
         selection: "Selection",
         selectionZoom: "Selection Zoom",
         zoomIn: "Zoom In",
         zoomOut: "Zoom Out",
         pan: "Panning",
         reset: "Reset Zoom",
        },
       },
      },
     ],
     type: "line", // This will be a mixed type chart
     height: 350,
     toolbar: {
      show: false,
     },
    },
    colors: ["#1E7889", "#84cc16", "#1E7889"],
    stroke: {
     width: [0, 4, 4], // Width for column (0) and line (4)
    },
    xaxis: {
     categories: this.labels,
     labels: {
      formatter: value => {
       // Convert value to Date with day to avoid parsing issues
       const date = new Date(value + "-01");
       // Format with short month and full year
       return new Intl.DateTimeFormat("fr-FR", {
        month: "short",
        year: "numeric",
       }).format(date);
      },
      show: true,
      rotate: -75,
      style: {
       colors: [],
       fontSize: "12px",
       fontFamily: "Helvetica, Arial, sans-serif",
       fontWeight: 500,
       cssClass: "apexcharts-xaxis-label",
      },
     },
     tickAmount: this.labels.length, // Show all labels based on data length
    },
    yaxis: [
     {
      title: {
       text: "Appels",
      },
     },
     {
      opposite: true,
      title: {
       text: "Taux de réponse",
      },
      labels: {
       show: true,
       formatter: function (value, timestamp, opts) {
        return value + "%";
       },
      },
     },
    ],
    tooltip: {
     shared: true,
     intersect: false,
    },
    plotOptions: {
     bar: {
      columnWidth: "50%",
     },
    },
   },
  };
 },
};
</script>

<style scoped>
/* You can add custom styles here */
</style>
