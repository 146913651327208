<template>
 <div class="lg:max-w-7xl lg:mx-auto lg:py-4">
  <div
   class="mx-4 mb-4"
   v-if="$route.name === 'DashboardTrend' || $route.name === 'Dashboard'">
   <div class="sm:hidden">
    <label for="tabs" class="sr-only">Select a tab</label>
    <select
     id="tabs"
     name="tabs"
     class="block w-full rounded-md border-gray-300 focus:border-teal-500 focus:ring-teal-500">
     <option selected>{{ $t("Tendances sur 12 mois") }}</option>
     <option>{{ $t("Analyse personnalisée") }}</option>
    </select>
   </div>
   <div class="hidden sm:block">
    <nav
     class="isolate flex divide-x divide-gray-200 rounded-lg shadow"
     aria-label="Tabs">
     <button
      @click="
       urlType ? changeTab(`/dashboard/${urlType}`) : changeTab(`/dashboard`)
      "
      :class="[
       $route.name === 'DashboardExpertStatistics' ||
       $route.name === 'Dashboard'
        ? 'bg-teal-600 text-white hover:bg-teal-700'
        : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-gray-700',
       'group relative min-w-0 flex-1 overflow-hidden rounded-l-lg px-4 py-4 text-center text-md font-bold focus:z-10',
      ]">
      <span>{{ $t("Analyse par période") }}</span>
      <span
       aria-hidden="true"
       :class="[
        $route.name === 'DashboardExpertStatistics' ||
        $route.name === 'Dashboard'
         ? 'bg-white'
         : 'bg-gray-300',
        'absolute inset-x-0 bottom-0 h-0.5',
       ]"></span>
     </button>
     <button
      @click="
       urlType
        ? changeTab(`/dashboard/trend-analysis/${urlType}`)
        : changeTab(`/dashboard/trend-analysis`)
      "
      :class="[
       $route.name === 'DashboardExpertStatisticsTrend' ||
       $route.name === 'DashboardTrend'
        ? 'bg-teal-600 text-white hover:bg-teal-700'
        : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-gray-700',
       'group relative min-w-0 flex-1 overflow-hidden rounded-r-lg px-4 py-4 text-center text-md font-bold focus:z-10',
      ]"
      aria-current="page">
      <span>{{ $t("Tendances sur 12 mois") }}</span>
      <span
       aria-hidden="true"
       :class="[
        $route.name === 'DashboardExpertStatisticsTrend' ||
        $route.name === 'DashboardTrend'
         ? 'bg-white'
         : 'bg-gray-300',
        'absolute inset-x-0 bottom-0 h-0.5 ',
       ]"></span>
     </button>
    </nav>
   </div>
  </div>
  <div
   class="col-span-4 flex mx-4 pb-3"
   v-if="
    $route.name === 'DashboardExpertStatistics' ||
    $route.name === 'DashboardExpertStatisticsTrend'
   ">
   <!-- <div
    :class="[
     showSideNav ? 'beta__logo' : 'left-40',
     'fixed z-1  mt-1 text-xs',
    ]">
    <span
     class="inline-flex items-center rounded-md bg-teal-500 px-2 py-1 text-md font-medium text-green-100 ring-1 ring-inset ring-green-100 ring-opacity-20">
     <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      class="w-4 h-4 mr-1">
      <path
       d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z" />
     </svg>
     {{ $t("beta") }}
    </span>
   </div> -->

   <PbxDashboardSelector
    :key="urlType"
    @action="setPeriod(selectedPeriod)"
    @all-selected="$emit('all-selected', $event)"
    :urlType="urlType"
    @elementToRemove="elementToRemove = $event"
    :queryElement="queryElement"
    :elements="this.pbxElements ? this.pbxElements : []" />
  </div>
  <div
   v-if="
    pbxElements &&
    pbxElements?.length &&
    ($route.name === 'DashboardExpertStatistics' ||
     $route.name === 'DashboardExpertStatisticsTrend')
   "
   class="pb-4 mx-4">
   <div v-if="showLess" class="lg:grid grid-cols-8 gap-2">
    <span
     v-for="element in pbxElements.slice(0, 8)"
     :key="element"
     class="flex items-center justify-between px-2 rounded-full bg-gray-200 py-1 text-xs font-medium text-gray-700">
     <span class="truncate" :title="element">
      {{ element }} -
      {{
       urlType && pbxMap
        ? urlType === "extension"
          ? pbxMap?.extensions[element]
          : urlType === "queue"
          ? pbxMap?.call_queues[element]?.name
          : ""
        : ""
      }}
     </span>
     <button
      type="button"
      @click="elementRemoved(element)"
      class="rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none">
      <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
       <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
      </svg>
     </button>
    </span>
    <!-- <div
    v-for="element in pbxElements.slice(0, 12)"
    :key="element"
    class="relative rounded-lg border border-gray-300 bg-whv-if="showLess"ite px-2 py-1 shadow-sm flex items-center hover:border-gray-400">
    <div @click="elementRemoved(element)" class="flex-1 min-w-0">
     <a
      :title="`Remove - ${element}`"
      class="focus:outline-none cursor-pointer">
      <span class="absolute inset-0" aria-hidden="true" />
      <p class="text-xs font-normal text-gray-900 truncate">
       {{ element }}
      </p>
     </a>
    </div>
   </div> -->
   </div>
   <div v-else class="lg:grid grid-cols-8 gap-2">
    <span
     v-for="element in pbxElements"
     :key="element"
     class="flex items-center justify-between px-2 rounded-full bg-gray-200 py-1 text-xs font-medium text-gray-700">
     <span class="truncate" :title="element">
      {{ element }} -
      {{
       urlType && pbxMap
        ? urlType === "extension"
          ? pbxMap?.extensions[element]
          : urlType === "queue"
          ? pbxMap?.call_queues[element]?.name
          : ""
        : ""
      }}
     </span>
     <button
      type="button"
      @click="elementRemoved(element)"
      class="rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none">
      <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
       <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
      </svg>
     </button>
    </span>
   </div>
   <div v-show="pbxElements.length > 12" class="my-1">
    <span
     v-show="showLess"
     class="text-xs italic text-gray-500 flex justify-center">
     {{ $t("andNumberMoreElements", { numb: pbxElements.length - 8 }) }}
    </span>
    <div class="flex justify-center mt-2">
     <button
      @click="showLess = !showLess"
      class="rounded-lg text-gray-700 bg-white hover:bg-grey-100 border-gray-300 hover:bg-gray-50 hover:shadow-md shadow-sm px-2 py-1 border text-xs">
      {{ showLess ? $t("showMore") : $t("showLess") }}
     </button>
    </div>
   </div>
  </div>
  <!-- Standard dashboard -->
  <div
   v-if="
    $route.name === 'DashboardExpertStatistics' || $route.name === 'Dashboard'
   "
   class="lg:grid lg:grid-cols-4 lg:gap-4 sm:space-y-0 lg:space-y-0 md:mx-4">
   <div class="col-span-4 flex justify-between">
    <div class="font-medium text-gray-700">
     <!-- <div
      :class="[
       $route.name === 'DashboardExpertStatistics' ? '' : 'left-32',
       'fixed z-1 ',
      ]">
      <span
       class="inline-flex items-center rounded-md bg-teal-500 px-2 py-1 text-md font-medium text-green-100 ring-1 ring-inset ring-green-100 ring-opacity-20 text-xs">
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-4 h-4 mr-1">
        <path
         d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z" />
       </svg>
       {{ $t("beta") }}
      </span>
     </div> -->

     <div
      class="fixed z-10"
      @mouseover="showDatePickers = true"
      @mouseleave="showDatePickers = false">
      <div class="flex items-center gap-4 text-md">
       <div
        class="flex items-center bg-gray-500 text-white shadow-md rounded-md border-gray-500 z-10 cursor-pointer font-bold">
        <p class="border-r px-2 py-2">
         {{
          toLocaleDateString(startDate) +
          (selectedPeriod !== "day" ? " - " + toLocaleDateString(endDate) : "")
         }}
        </p>
        <div
         @click="showDatePickers = true"
         class="px-2 py-2 cursor-pointer bg-white text-gray-500 shadow-md hover:text-white hover:bg-blue-400 rounded-r-md">
         <font-awesome-icon icon="calendar" class="flex-shrink-0" />
        </div>
       </div>
      </div>
      <transition name="fade">
       <div
        v-show="showDatePickers"
        class="space-y-1 transition ease-in-out delay-150 mt-1 px-1 text-left">
        <div v-for="option in calendarOptions" :key="option.value">
         <div v-if="option.value === 'custom'">
          <v-date-picker
           class="inline-block h-full w-full"
           color="teal"
           v-model="customDate"
           :max-date="new Date()"
           mode="date"
           is-range>
           <template v-slot="{ togglePopover }">
            <button
             @click="togglePopover()"
             :class="{
              'bg-blue-500 text-white': selectedPeriod === option.value,
              'hover:bg-blue-600 hover:text-white':
               selectedPeriod !== option.value,
             }"
             class="flex items-center text-xs px-4 py-2 rounded-md focus:outline-none bg-gray-400 text-white w-full text-left">
             <font-awesome-icon
              :icon="option.icon"
              class="flex-shrink-0 mr-1.5" />
             {{ $t("dashboards.custom") }}
            </button>
           </template>
          </v-date-picker>
         </div>
         <button
          v-else
          @click="selectPeriod(option.value)"
          :class="{
           'bg-blue-500 text-white': selectedPeriod === option.value,
           'hover:bg-blue-600 hover:text-white':
            selectedPeriod !== option.value,
          }"
          class="flex items-center text-xs px-4 py-2 rounded-md focus:outline-none bg-gray-400 text-white w-full text-left">
          <font-awesome-icon :icon="option.icon" class="flex-shrink-0 mr-1.5" />
          {{ $t(option.name) }}
         </button>
        </div>
        <div class="">
         <TimeSelector
          @timeSelector="setPeriod(selectedPeriod)"
          :elements="this.pbxElements ? this.pbxElements : []"
          :clearTimeSelector="clearTimeSelector" />
        </div>
       </div>
      </transition>
     </div>
    </div>
    <div class="z-1 mt- flex lg:mt-0 gap-2">
     <span class="hidden sm:block">
      <button
       type="button"
       :title="$t('Envoyer un rapport')"
       class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-100 focus:outline-none encom_ring_color"
       @click="
        this.$refs.popupShareReport.show({
         title: $t('pbxReport.shareTitle'),
         message: $t('pbxReport.shareMessage'),
         isSchedule: false,
        })
       ">
       <font-awesome-icon
        icon="share"
        class="h-5 w-5 text-gray-500"
        aria-hidden="true" />
      </button>
     </span>

     <span class="hidden sm:block">
      <button
       type="button"
       :title="$t('Planifier un rapport')"
       class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-100 focus:outline-none encom_ring_color"
       @click="
        this.$refs.popupShareScheduleReport.show({
         title:
          $t('Planifier un rapport') +
          ' ' +
          $t('daily') +
          ', ' +
          $t('weekly') +
          ' ' +
          $t('or') +
          ' ' +
          $t('monthly'),
         message: $t('Le rapport a été planifié avec succès'),
         isSchedule: true,
        })
       ">
       <font-awesome-icon
        :icon="['far', 'calendar-check']"
        class="h-5 w-5 text-gray-500"
        aria-hidden="true" />
      </button>
     </span>
     <span class="hidden sm:block">
      <button
       type="button"
       :title="$t('Créer une alerte')"
       @click="
        this.$refs.popupCreateAlert.show({
         title: $t('Créer une alerte'),
         message: $t(''),
         isSchedule: false,
        })
       "
       class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-100 focus:outline-none encom_ring_color">
       <font-awesome-icon
        :icon="['far', 'bell']"
        class="h-5 w-5 text-gray-500"
        aria-hidden="true" />
      </button>
     </span>
     <div
      v-if="$route.name === 'Dashboard'"
      @click.prevent="this.$refs.tutorial.show()"
      title="Ouvrir le tutoriel"
      :class="['sm:ml-6 mt-0.5 text-xs cursor-pointer']">
      <span
       class="shadow-md hover:shadow-lg inline-flex items-center rounded-full bg-blue-400 hover:bg-blue-500 px-2 py-2 hover:ring-1 hover:ring-inset hover:ring-blue-100 ring-opacity-20">
       <font-awesome-icon icon="question" class="text-blue-100 h-5 w-5" />
      </span>
     </div>
    </div>
    <!-- <div class="text-xs font-medium text-gray-700 mr-52">
     <div class="fixed z-10 flex gap-4">
      <button
       class="flex items-center bg-white shadow-md rounded-md border-gray-400 px-2 py-2 text-xs font-medium text-gray-700">
       Total des appels
      </button>
      <button
       class="flex items-center bg-white shadow-md rounded-md border-gray-400 px-2 py-2 text-xs font-medium text-gray-700">
       test
      </button>
      <button
       class="flex items-center bg-white shadow-md rounded-md border-gray-400 px-2 py-2 text-xs font-medium text-gray-700">
       test
      </button>
     </div>
    </div> -->
   </div>

   <!-- stats total calls cards -->
   <div
    v-if="
     (totalCallsInboundPbx ||
      totalCallsInternalPbx ||
      totalCallsOutboundPbx ||
      talkingInboundPbx ||
      talkingInternalPbx ||
      talkingOutboundPbx) &&
     !urlType
    "
    class="lg:col-span-4 sm:col-span-1 space-y-2 pt-2 pb-12 bg-white shadow-md px-2 rounded-sm"
    id="stats-total">
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md">
     <h2 class="uppercase text-lg font-bold mb-">total des appels</h2>
    </div>
    <div
     class="lg:flex justify-center space-y-6 lg:space-y-0 lg:space-x-6"
     v-if="totalCallsArrPbx && totalCallsArrDurationPbx"
     :key="totalCallsArrPbx && totalCallsArrDurationPbx">
     <TotalCallsCard
      :key="totalCallsArrPbx"
      title="Nombre"
      :duration="false"
      :inboundCalls="totalCallsInboundPbx"
      :outboundCalls="totalCallsOutboundPbx"
      :internalCalls="totalCallsInternalPbx"
      :totalCallsArr="totalCallsArrPbx" />
     <TotalCallsCard
      :key="totalCallsArrDurationPbx"
      title="Durée"
      :duration="true"
      :inboundCalls="talkingInboundPbx"
      :outboundCalls="talkingOutboundPbx"
      :internalCalls="talkingInternalPbx"
      :totalCallsArr="totalCallsArrDurationPbx" />
    </div>

    <div class="pt-4">
     <div class="flex justify-end items-end">
      <span class="text-sm text-gray-700">
       * Appels émis vers l'extérieur et appels reçus depuis l'extérieur
      </span>
     </div>
     <!-- <div class="flex justify-end items-end">
      <span class="text-sm text-gray-700"> ** File uniquement </span>
     </div> -->
    </div>
   </div>
   <!-- stats incomming calls cards -->
   <!-- v-if="dataQueueTable?.length && urlType !== 'extension'" -->
   <div
    v-if="totalCalls"
    class="col-span-4 space-y-2 pt-2 pb-12 bg-white shadow-md px-2 rounded-sm"
    id="stats-incomming">
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mb-6">
     <h2 class="uppercase text-lg font-bold mb-">
      APPELS reçus
      {{
       urlType === "extension"
        ? "par mes utilisateurs"
        : "SUR MES FILES D`ATTENTES"
      }}
     </h2>
    </div>
    <div class="lg:grid grid-cols-3 lg:space-x-4 space-y-6 lg:space-y-0">
     <lost-calls-card
      v-if="totalCalls"
      :key="totalCalls"
      :total="totalCalls"
      :lost="lostCalls"
      :answered_15="answered_15"
      :talking_10="talking_10 ? talking_10 : 0" />
     <avg-answer-time-card
      v-if="totalCalls && waiting"
      :key="totalCalls"
      :total="totalCalls"
      :lost="lostCalls"
      :answered_15="answered_15"
      :talking_10="talking_10 ? talking_10 : 0"
      :urlType="urlType" />
     <ten-seconds-calls-card
      v-if="totalCalls"
      :key="totalCalls"
      :total="totalCalls"
      :lost="lostCalls"
      :answered_15="answered_15"
      :talking_10="talking_10 ? talking_10 : 0" />
    </div>
    <!-- stats duration total / duration avg / longest call  -->
    <div
     v-if="dataColumnChart?.length && dataColumnChartCategorie?.length"
     class="col-span-4 bg-white rounded-lg py-2">
     <div class="flex justify-between"></div>
    </div>
    <div class="lg:grid grid-cols-subgrid col-span-4">
     <AnsweredCard
      :key="answerType"
      v-if="dataColumnChart.length && dataColumnChartHour.length"
      @quickPeriod="quickPeriodAnswered = $event"
      :quickPeriod="quickPeriodAnswered"
      @selectPeriod="periodSelectorAnswered = $event"
      :seriesForColumn="
       answerType === 'hour' ? dataColumnChartHour : dataColumnChart
      "
      :categoriesForColumn="
       answerType === 'hour'
        ? dataColumnChartHourCategorie
        : dataColumnChartCategorie
      "
      :totalAnswered="this.getAnsweredNumb(dataColumnChart)"
      :totalUnanswered="this.getUnansweredNumb(dataColumnChart)"
      :selectedValue="answerType"
      @input="answerType = $event" />
    </div>
    <div class="lg:grid lg:space-y-0 sm:space-y-8 grid-cols-4 lg:gap-2 text-sm">
     <div
      v-if="talking && totalCalls"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <p>
         La durée totale des appels reçus est de
         <span class="">
          {{
           (parseInt(formatSecsToHourMinAndSecsNoDays(talking).split(":")[0])
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking).split(":")[0]
              ) +
              "h" +
              " "
            : "") +
           (parseInt(formatSecsToHourMinAndSecsNoDays(talking).split(":")[1])
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking).split(":")[1]
              ) + "min"
            : "")
          }} </span
         >. avec une durée moyenne d’appel de<span class="">
          {{
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(":")[0]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(
                ":"
               )[0]
              ) + "h"
            : "") +
           " " +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(":")[1]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(
                ":"
               )[1]
              ) + "min"
            : "") +
           " " +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(":")[2]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talking / totalCalls).split(
                ":"
               )[2]
              ) + "sec"
            : "")
          }}
         </span>
         .
        </p>
       </div>
      </div>
     </div>
     <div
      v-if="lowestRateHour"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le taux de décroché le plus bas* :</span
         >
         <div class="pt-1">
          Entre
          <span class=""> {{ lowestRateHour.split(":")[0] }}h </span>
          et
          <span class="">
           {{
            (parseInt(lowestRateHour.split(":")[0]) + 1 + "0").slice(0, 2)
           }}h </span
          >, {{ (100 - worstAnsweredRate).toFixed(0) }}% des appels ne sont pas
          décrochés.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div
      v-if="bestRateHour"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le meilleur taux de décroché* :</span
         >
         <div class="pt-1">
          Entre
          <span class=""> {{ bestRateHour.split(":")[0] }}h </span>
          et
          <span class="">
           {{
            (parseInt(bestRateHour.split(":")[0]) + 1 + "0").slice(0, 2)
           }}h </span
          >, {{ bestAnsweredRate?.toFixed(0) }}% des appels sont décrochés.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div
      v-if="mostCallsHour"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le plus d’appels* :</span
         >
         <div class="pt-1">
          Entre
          <span class=""> {{ mostCallsHour.split(":")[0] }}h </span>
          et
          <span class="">
           {{
            (parseInt(mostCallsHour.split(":")[0]) + 1 + "0").slice(0, 2)
           }}h </span
          >,
          {{ mostCalls }}
          ont été reçus .
         </div>
        </div>
       </div>
      </div>
     </div>
     <div class="col-span-4 flex justify-end text-right items-end">
      <span class="text-sm text-gray-700">
       * Pour les tranches horaires comprises entre
       {{ parseInt(pbxTimeSelected[0].slice(0, 2)) }}h et
       {{ parseInt(pbxTimeSelected[1].slice(0, 2)) }}h.
      </span>
     </div>
    </div>
   </div>
   <!-- stats outgoing calls cards -->
   <div
    v-if="
     dataColumnChartOutbound?.length &&
     dataColumnChartOutboundHour?.length &&
     !urlType
    "
    class="col-span-4 space-y-2 pt-2 pb-12 bg-white shadow-md px-2 rounded-sm"
    id="stats-outgoing">
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md">
     <h2 class="uppercase text-lg font-bold mb-">appels émis externe</h2>
    </div>
    <div class="col-span-4">
     <AnsweredCard
      :key="answerType"
      v-if="
       dataColumnChartOutbound.length &&
       dataColumnChartOutboundHour.length &&
       dataColumnChartOutboundHourCategorie.length &&
       dataColumnChartCategorieOutbound.length
      "
      @quickPeriod="quickPeriodAnswered = $event"
      :quickPeriod="quickPeriodAnswered"
      @selectPeriod="periodSelectorAnswered = $event"
      :seriesForColumn="
       answerType === 'hour'
        ? dataColumnChartOutboundHour
        : dataColumnChartOutbound
      "
      :categoriesForColumn="
       answerType === 'hour'
        ? dataColumnChartOutboundHourCategorie
        : dataColumnChartCategorieOutbound
      "
      :totalAnswered="this.getAnsweredNumb(dataColumnChartOutbound)"
      :totalUnanswered="this.getUnansweredNumb(dataColumnChartOutbound)"
      :selectedValue="answerType"
      @input="answerType = $event" />
    </div>
    <div class="lg:grid grid-cols-4 gap-2 text-sm">
     <div
      v-if="talkingOutbound && totalCallsOutbound"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <p>
         La durée totale des appels émis est de
         <span class="">
          {{
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talkingOutbound).split(":")[0]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talkingOutbound).split(":")[0]
              ) +
              "h" +
              " "
            : "") +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(talkingOutbound).split(":")[1]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(talkingOutbound).split(":")[1]
              ) + "min"
            : "")
          }} </span
         >. avec une durée moyenne d’appel de<span class="">
          {{
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(
             talkingOutbound / totalCallsOutbound
            ).split(":")[0]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(
                talkingOutbound / totalCallsOutbound
               ).split(":")[0]
              ) + "h"
            : "") +
           " " +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(
             talkingOutbound / totalCallsOutbound
            ).split(":")[1]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(
                talkingOutbound / totalCallsOutbound
               ).split(":")[1]
              ) + "min"
            : "") +
           " " +
           (parseInt(
            formatSecsToHourMinAndSecsNoDays(
             talkingOutbound / totalCallsOutbound
            ).split(":")[2]
           )
            ? parseInt(
               formatSecsToHourMinAndSecsNoDays(
                talkingOutbound / totalCallsOutbound
               ).split(":")[2]
              ) + "sec"
            : "")
          }}
         </span>
         .
        </p>
       </div>
      </div>
     </div>
     <div
      v-if="lowestRateHourOutbound"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le taux de décroché le plus bas* :</span
         >
         <div class="pt-1">
          Entre
          <span class=""> {{ lowestRateHourOutbound.split(":")[0] }}h </span>
          et
          <span class="">
           {{ parseInt(lowestRateHourOutbound.split(":")[0]) + 1 }}h </span
          >, {{ (100 - worstAnsweredRateOutbound).toFixed(0) }}% des appels ne
          sont pas décrochés.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div
      v-if="bestRateHourOutbound"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le meilleur taux de décroché* :</span
         >
         <div class="pt-1">
          Entre
          <span class=""> {{ bestRateHourOutbound.split(":")[0] }}h </span>
          et
          <span class="">
           {{
            (parseInt(bestRateHourOutbound.split(":")[0]) + 1 + "0").slice(
             0,
             2
            )
           }}h</span
          >, {{ bestAnsweredRateOutbound.toFixed(0) }}% des appels sont
          décrochés.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div
      v-if="mostCallsHourOutbound"
      class="col-span-1 px-2 py-2 bg-blue-100 rounded-lg mb-1 shadow-md text-blue-800 mx-2">
      <div class="flex justify-center items-center h-full">
       <div class="">
        <div>
         <span class="font-bold">
          Tranche horaire avec le plus d’appels* :</span
         >
         <div class="pt-1">
          Entre
          <span class=""> {{ mostCallsHourOutbound.split(":")[0] }}h </span>
          et
          <span class="">
           {{
            (
             parseInt(mostCallsHourOutbound.split(":")[0].split(":")[0]) +
             1 +
             "0"
            ).slice(0, 2)
           }}h </span
          >, {{ mostCallsOutbound }} ont été émis.
         </div>
        </div>
       </div>
      </div>
     </div>
     <div class="col-span-4 flex justify-end text-right items-end">
      <span class="text-sm text-gray-700">
       * Pour les tranches horaires comprises entre
       {{ parseInt(pbxTimeSelected[0].slice(0, 2)) }}h et
       {{ parseInt(pbxTimeSelected[1].slice(0, 2)) }}h.
      </span>
     </div>
    </div>
   </div>
   <!-- stats top 10 users -->
   <div
    v-if="dataColumnChartTopUsers?.length && urlType !== 'queue'"
    class="col-span-4 pb-12 space-y-2 py-2 bg-white shadow-md px-2 rounded-sm"
    id="stats-top">
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mx-0">
     <h2 class="uppercase text-lg font-bold">Top 10 utilisateurs</h2>
    </div>
    <!-- <div
     class="px-2 py-2 text-sm bg-blue-100 rounded-lg shadow-md text-blue-800 mx-2 -mb-4">
     Voici un aperçu des dix
     <span class="font-medium">principaux utilisateurs</span> en fonction du
     nombre d'appels entrants et sortants auxquels ils ont répondu. Les données
     révèlent une répartition diversifiée de l'activité téléphonique parmi ces
     utilisateurs, avec certains occupant des positions plus élevées en raison
     de leur <span class="font-medium">engagement accru dans les appels</span>.
     Ce classement offre un aperçu précieux de l'utilisation des communications
     au sein de notre réseau, mettant en lumière les membres les plus actifs et
     les tendances d'utilisation dominantes.
    </div> -->

    <TopUsersCard
     :key="dataColumnChartTopUsers"
     v-if="
      dataColumnChartTopUsers.length && dataColumnChartTopUsersLabels.length
     "
     @quickPeriod="quickPeriodAnswered = $event"
     :quickPeriod="quickPeriodAnswered"
     @selectPeriod="periodSelectorAnswered = $event"
     :seriesForColumn="dataColumnChartTopUsers"
     :categoriesForColumn="dataColumnChartTopUsersLabels" />
    <div class="flex justify-end items-end">
     <span class="text-sm text-gray-700 w-3/5">
      (1) Appels externes reçus ayant été décrochés par l'utilisateur. Les
      appels non décrochés ne sont pas pris en compte.
     </span>
    </div>
    <div class="flex justify-end items-end">
     <span class="text-sm text-gray-700 w-3/5">
      (2) Appels externes émis par l'utilisateur et répondus par l'appelé. Les
      appels non répondus ne sont pas pris en compte.
     </span>
    </div>
    <div class="flex justify-end items-end">
     <span class="text-sm text-gray-700 w-3/5">
      (3) Appels internes émis ou reçus par l'utilisateur ayant été répondus.
      Les appels non répondus ne sont pas pris en compte.
     </span>
    </div>
   </div>
   <div
    v-if="urlType ? !pbxElements || pbxElements?.length == 0 : false"
    class="col-span-4 pb-12 space-y-2 py-2 bg-white shadow-md px-2 rounded-sm">
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mx-0">
     <h2 v-if="urlType === 'queue'" class="uppercase text-lg font-bold">
      Tableau de Bord Files D'attente
     </h2>
     <h2 v-if="urlType === 'extension'" class="uppercase text-lg font-bold">
      Tableau de bord Utiliateurs
     </h2>
    </div>
    <div class="pt-8 overflow-x-auto sm:-mx-6 lg:-mx-0">
     <div
      v-if="!pbxElements || pbxElements.length <= 0"
      class="text-center max-w-lg mx-auto space-y-4">
      <font-awesome-icon
       icon="gauge-high"
       class="mx-auto h-10 w-10 text-teal-600"
       aria-hidden="true" />
      <h1 class="text-gray-900 font-bold text-2xl">DASHBOARD</h1>
      <h3 class="mt-2 text-lg font-medium text-gray-900">
       <span v-if="urlType === 'queue'">
        Les indicateurs essentiels pour analyser les appels reçus sur vos files
        d’attente.
       </span>
       <span v-if="urlType === 'extension'">
        Les indicateurs essentiels pour analyser les appels par utilisateurs.
       </span>
      </h3>
      <div class="pt-10">
       <PleaseSelectElement
        v-if="urlType === 'queue'"
        dataDescription="Veuillez sélectionner une file et une période" />
       <PleaseSelectElement
        v-if="urlType === 'extension'"
        dataDescription="Veuillez sélectionner un utilisateur et une période" />
      </div>
     </div>
     <div
      v-else
      class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-6">
      <div class="overflow-hidden border-b border-gray-200 rounded-md">
       <NoData />
      </div>
     </div>
    </div>
   </div>

   <!-- <div class="col-span-1" v-if="dataQueueTable.length">
    <TopQueuesCard :data="dataQueueTable" />
   </div> -->
  </div>
  <!-- KPI dashboard -->
  <div
   v-if="
    $route.name === 'DashboardExpertStatisticsTrend' ||
    $route.name === 'DashboardTrend'
   "
   class="lg:grid lg:grid-cols-4 lg:gap-4 sm:space-y-0 lg:space-y-0 md:mx-4">
   <!-- stats incomming calls cards -->
   <div
    v-if="
     $route.name === 'DashboardExpertStatisticsTrend'
      ? urlType !== 'extension' && urlType
        ? pbxElements || pbxElements?.length > 0
        : false
      : true
    "
    class="col-span-4 space-y-2 pt-2 pb-12 bg-white shadow-md px-2 rounded-sm"
    id="stats-incomming">
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mb-6">
     <h2 class="uppercase text-lg font-bold mb-">
      appels reçus SUR MES FILES D'ATTENTES
     </h2>
    </div>
    <div class="">
     <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
      <!-- Answered Calls -->
      <div
       v-if="totalCalls && prevTotalCalls"
       class="bg-white shadow rounded-lg p-6">
       <h2 class="text-xl font-semibold text-gray-800 mb-4">
        Total des appels
       </h2>
       <div class="lg:flex justify-between lg:pr-8">
        <div>
         <p class="text-3xl font-bold text-teal-600">
          {{ totalCalls }}
         </p>
         <p class="text-gray-500">{{ currentMonthName }} {{ currentYear }}</p>
        </div>
        <div>
         <p
          :class="{
           'text-green-500': totalTrend > 0,
           'text-red-500': totalTrend < 0,
           'text-gray-500': totalTrend === 0,
          }"
          v-if="totalTrend != 0"
          class="items-center">
          <span class="flex items-center">
           <svg
            v-if="totalTrend > 0"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="w-4 h-4 mr-2">
            <path
             stroke-linecap="round"
             stroke-linejoin="round"
             stroke-width="2"
             d="M5 10l7-7m0 0l7 7m-7-7v18" />
           </svg>
           <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="w-4 h-4 mr-2">
            <path
             stroke-linecap="round"
             stroke-linejoin="round"
             stroke-width="2"
             d="M19 14l-7 7m0 0l-7-7m7 7V3" />
           </svg>
           {{ Math.abs(totalTrend) }}%
          </span>
          comparé à {{ lastMonthName }}
         </p>
         <p v-else class="items-center">
          <span class="flex items-center text-gray-500">
           Aucun changement
          </span>
          comparé à {{ lastMonthName }}
         </p>
        </div>
       </div>
      </div>

      <!-- Unanswered Calls -->
      <div
       v-if="totalCalls && carCalls && prevCarCalls"
       class="bg-white shadow rounded-lg p-6">
       <h2 class="text-xl font-semibold text-gray-800 mb-4">Taux de réponse</h2>
       <div class="lg:flex justify-between lg:pr-8">
        <div>
         <p class="text-3xl font-bold text-lime-500">{{ carCalls }}%</p>
         <p class="text-gray-500">{{ currentMonthName }} {{ currentYear }}</p>
        </div>
        <div>
         <p
          :class="{
           'text-green-500': carTrend > 0,
           'text-red-500': carTrend < 0,
           'text-gray-500': carTrend === 0,
          }"
          v-if="carTrend != 0"
          class="items-center">
          <span class="flex items-center">
           <svg
            v-if="carTrend > 0"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="w-4 h-4 mr-2">
            <path
             stroke-linecap="round"
             stroke-linejoin="round"
             stroke-width="2"
             d="M5 10l7-7m0 0l7 7m-7-7v18" />
           </svg>
           <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="w-4 h-4 mr-2">
            <path
             stroke-linecap="round"
             stroke-linejoin="round"
             stroke-width="2"
             d="M19 14l-7 7m0 0l-7-7m7 7V3" />
           </svg>
           {{ Math.abs(carTrend) }}%
          </span>
          comparé à {{ lastMonthName }}
         </p>
         <p v-else class="items-center">
          <span class="flex items-center text-gray-500">
           Aucun changement
          </span>
          comparé à {{ lastMonthName }}
         </p>
        </div>
       </div>
      </div>

      <!-- Average Wait Time -->
      <div
       v-if="averageWaitTime && averageWaitTime && prevAverageWaitTime"
       class="bg-white shadow rounded-lg p-6">
       <h2 class="text-xl font-semibold text-gray-800 mb-4">
        Temps d'attente moyen
       </h2>
       <div class="lg:flex justify-between lg:pr-8">
        <div>
         <p class="text-3xl font-bold text-blue-600">
          {{ formatSecsToMinAndSecs(parseInt(averageWaitTime)) }}
          <!-- <span class="text-lg">min</span> -->
         </p>
         <p class="text-gray-500">{{ currentMonthName }} {{ currentYear }}</p>
        </div>
        <div>
         <p
          :class="{
           'text-green-500': waitTimeTrend < 0,
           'text-red-500': waitTimeTrend > 0,
           'text-gray-500': waitTimeTrend === 0,
          }"
          v-if="waitTimeTrend != 0"
          class="items-center">
          <span class="flex items-center">
           <svg
            v-if="waitTimeTrend > 0"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="w-4 h-4 mr-2">
            <path
             stroke-linecap="round"
             stroke-linejoin="round"
             stroke-width="2"
             d="M5 10l7-7m0 0l7 7m-7-7v18" />
           </svg>
           <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="w-4 h-4 mr-2">
            <path
             stroke-linecap="round"
             stroke-linejoin="round"
             stroke-width="2"
             d="M19 14l-7 7m0 0l-7-7m7 7V3" />
           </svg>
           {{ Math.abs(waitTimeTrend) }}%
          </span>
          comparé à {{ lastMonthName }}
         </p>
         <p v-else class="items-center">
          <span class="flex items-center text-gray-500">
           Aucun changement
          </span>
          comparé à {{ lastMonthName }}
         </p>
        </div>
       </div>
      </div>
     </div>

     <!-- Trend Graphs Section -->
     <div
      v-if="
       dataColumnChartMonth.length > 0 && dataColumnChartMonthWaiting.length > 0
      "
      class="bg-white shadow rounded-lg p-6 mb-4">
      <!-- <h2 class="text-2xl font-bold text-gray-800 mb-4">Monthly Trends</h2> -->
      <div class="space-y-12">
       <div>
        <TrendChartAnswered
         :dataSeries="dataColumnChartMonth"
         :labels="dataColumnChartMonthLabels" />
       </div>
       <div>
        <TrendChartWaitTime
         :dataSeries="dataColumnChartMonthWaiting"
         :labels="dataColumnChartMonthWaitingLabels" />
       </div>
      </div>
     </div>
    </div>
   </div>

   <div
    v-if="urlType ? !pbxElements || pbxElements?.length == 0 : false"
    class="col-span-4 pb-12 space-y-2 py-2 bg-white shadow-md px-2 rounded-sm">
    <div
     class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mx-0">
     <h2 v-if="urlType === 'queue'" class="uppercase text-lg font-bold">
      Tableau de Bord Files D'attente
     </h2>
     <h2 v-if="urlType === 'extension'" class="uppercase text-lg font-bold">
      Tableau de bord Utiliateurs
     </h2>
    </div>
    <div class="pt-8 overflow-x-auto sm:-mx-6 lg:-mx-0">
     <div
      v-if="!pbxElements || pbxElements.length <= 0"
      class="text-center max-w-lg mx-auto space-y-4">
      <font-awesome-icon
       icon="gauge-high"
       class="mx-auto h-10 w-10 text-teal-600"
       aria-hidden="true" />
      <h1 class="text-gray-900 font-bold text-2xl">DASHBOARD</h1>
      <h3 class="mt-2 text-lg font-medium text-gray-900">
       <span v-if="urlType === 'queue'">
        Les indicateurs essentiels pour analyser les appels reçus sur vos files
        d’attente.
       </span>
       <span v-if="urlType === 'extension'">
        Les indicateurs essentiels pour analyser les appels par utilisateurs.
       </span>
      </h3>
      <div class="pt-10">
       <PleaseSelectElement
        v-if="urlType === 'queue'"
        dataDescription="Veuillez sélectionner une file et une période" />
       <PleaseSelectElement
        v-if="urlType === 'extension'"
        dataDescription="Veuillez sélectionner un utilisateur et une période" />
      </div>
     </div>
     <div
      v-else
      class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-6">
      <div class="overflow-hidden border-b border-gray-200 rounded-md">
       <NoData />
      </div>
     </div>
    </div>
   </div>

   <!-- <div class="col-span-1" v-if="dataQueueTable.length">
    <TopQueuesCard :data="dataQueueTable" />
   </div> -->
  </div>
  <TransitionRoot as="template" :show="reportList">
   <Dialog as="div" class="relative z-40" @close="reportList = false">
    <div class="fixed inset-0" />

    <div class="fixed inset-0 overflow-hidden">
     <div class="absolute inset-0 overflow-hidden">
      <div
       class="pointer-events-none fixed inset-y-0 right-0 top-12 flex max-w-full pl-10">
       <TransitionChild
        as="template"
        enter="transform transition ease-in-out duration-500 sm:duration-700"
        enter-from="translate-x-full"
        enter-to="translate-x-0"
        leave="transform transition ease-in-out duration-500 sm:duration-700"
        leave-from="translate-x-0"
        leave-to="translate-x-full">
        <DialogPanel class="pointer-events-auto w-screen max-w-7xl">
         <div
          class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
          <div class="px-4 sm:px-6">
           <div class="flex items-start justify-between">
            <DialogTitle class="w-full text-lg font-medium text-gray-900">
             <div
              class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mx-0">
              <h2 class="uppercase text-lg font-bold">
                MES RAPPORTS PROGRAMMES
               <!-- {{
                 urlType === "queue"
                  ? "PAR FILE"
                  : urlType === "extension"
                  ? "PAR UTILISATEUR"
                  : "PAR numéro"
                }} -->
              </h2>
             </div>
             <!-- {{ $t("agentDetails", { agentNumber: agentNumber, agentName: agentName }) }} -->
            </DialogTitle>
            <div class="ml-3 flex h-7 items-center">
             <button
              type="button"
              class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
              @click="reportList = false">
              <span class="sr-only">Close panel</span>
              <XIcon class="h-6 w-6" aria-hidden="true" />
             </button>
            </div>
           </div>
          </div>
          <div class="relative mt-4 flex-1">
           <!-- Replace with your content -->
           <div class="absolute border-gray-200 bg-white w-full pb-2">
            <div class="flex flex-col mb-0">
             <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
              <div
               class="py-4 align-middle inline-block min-w-full sm:px-4 lg:px-4">
               <div
                class="shadow overflow-hidden border-b border-gray-200 rounded-md items-center">
                <div
                 class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg items-center">
                 <table
                  class="min-w-full divide-y divide-gray-300 items-center">
                  <thead class="bg-gray-50">
                   <tr>
                    <!-- Render the th element only if header.name is not 'id' -->
                    <template v-for="(header, index) in thead">
                     <th
                      v-if="header.name !== 'id'"
                      :key="index"
                      scope="col"
                      :class="{
                       'pl-4 pr-3 sm:pl-6': index === 1,
                       'px-3': index !== 1,
                      }"
                      class="py-3.5 text-left text-sm font-semibold text-gray-900 capitalize">
                      <span
                       v-if="
                        header.name.includes('_') &&
                        !header.name.includes('pattern') &&
                        header.name !== 'start_at'
                       ">
                       {{ $t(header.name.split("_")[1]) }}
                      </span>
                      <span v-else-if="header.name === 'dns'"> élément </span>
                      <span v-else-if="header.name === 'repeat'">
                       Planifié
                      </span>
                      <span v-else-if="header.name === 'start_at'">
                       Début
                      </span>
                      <span v-else-if="header.name === 'repeat_pattern'">
                       Chaque
                      </span>
                      <span v-else-if="header.name === 'name'">
                       {{ $t("name") }}
                      </span>
                      <span v-else-if="header.name === 'element_type'">
                       {{ $t("type") }}
                      </span>
                      <span v-else>
                       {{ $t(header.name) }}
                      </span>
                     </th>
                    </template>
                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                     <span class="sr-only">Edit</span>
                    </th>
                   </tr>
                  </thead>
                  <tbody
                   class="divide-y divide-gray-200 bg-white truncate items-center">
                   <slot
                    :row="row"
                    v-for="(row, rowIndex) in tbody"
                    :key="rowIndex"
                    class="items-center">
                    <tr class="items-center">
                     <!-- Render the td element only if the key is not 'id' -->
                     <template v-for="(value, key, index) in row">
                      <td
                       v-if="key !== 'id' && key !== 'report_type'"
                       :key="key"
                       :class="{
                        'pl-4 pr-3 sm:pl-6 font-medium text-gray-900':
                         index === 1,
                        'px-3 text-gray-500': index !== 1,
                       }"
                       class="whitespace-nowrap text-sm py-4 truncate max-w-sm">
                       <span class="truncate" v-if="value">
                        <span v-if="key === 'start_at' || key === 'end'">
                         <span v-if="row['repeat'] == 1 && key === 'end'">
                          <svg
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 20 20"
                           fill="currentColor"
                           class="w-5 h-5">
                           <path
                            d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
                          </svg>
                         </span>
                         <span
                          v-else-if="row['repeat'] == 1 && key === 'start'">
                          {{ $d(new Date(row["start_at"]), "shortText", "fr") }}
                         </span>
                         <span v-else>
                          {{ $d(new Date(value), "shortText", "fr") }}
                         </span>
                        </span>
                        <span v-else-if="key === 'repeat'">
                         <div v-if="value">
                          <span
                           class="px-2 py-1 bg-green-200 text-green-600 rounded-xl"
                           >Oui</span
                          >
                         </div>
                         <div v-else>
                          <span class="px-2 py-1 bg-blue-300 text-blue-600"
                           >Non</span
                          >
                         </div>
                        </span>

                        <span v-else-if="key === 'report_type'">
                         {{
                          value === "origins"
                           ? "Origine"
                           : value === "didReport"
                           ? "Rapport détaillé"
                           : value === "report"
                           ? "Rapport détaillé"
                           : value === "dashboard"
                           ? "Tableau de bord"
                           : value === "dashboard"
                           ? "Tableau de bord"
                           : $t(value)
                         }}
                        </span>
                        <span v-else-if="key === 'element_type'">
                         {{
                          value == 0
                           ? $t("extension")
                           : value == 4
                           ? $t("queue")
                           : value == "did"
                           ? $t("number")
                           : value == "*"
                           ? "Tableau de bord"
                           : $t(value)
                         }}
                        </span>
                        <span v-else-if="key === 'dns'">
                         <span v-if="value === '*'">
                          <svg
                           xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 20 20"
                           fill="currentColor"
                           class="w-5 h-5">
                           <path
                            d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
                          </svg>
                         </span>
                         <span v-else>
                          <!-- {{ value.split(",") }} -->
                          <div
                           v-if="showLessReport.indexOf(rowIndex) < 0"
                           class="space-y-1">
                           <span
                            v-for="element in value.split(',').slice(0, 2)"
                            :key="element"
                            class="flex items-center justify-between px-2 rounded-full bg-gray-200 py-1 text-xs font-medium text-gray-700">
                            <span class="truncate" :title="element">
                             {{ element }} -
                             {{
                              row["element_type"] && pbxMap
                               ? row["element_type"] == 0
                                 ? pbxMap?.extensions[element]
                                 : row["element_type"] == 4
                                 ? pbxMap?.call_queues[element]?.name
                                 : ""
                               : ""
                             }}
                            </span>
                           </span>
                          </div>
                          <div v-else class="space-y-1">
                           <span
                            v-for="element in value.split(',')"
                            :key="element"
                            class="flex items-center justify-between px-2 rounded-full bg-gray-200 py-1 text-xs font-medium text-gray-700">
                            <span class="truncate" :title="element">
                             {{ element }} -
                             {{
                              row["element_type"] && pbxMap
                               ? row["element_type"] == 0
                                 ? pbxMap?.extensions[element]
                                 : row["element_type"] == 4
                                 ? pbxMap?.call_queues[element]?.name
                                 : ""
                               : ""
                             }}
                            </span>
                           </span>
                          </div>
                          <div v-show="value.split(',').length > 2" class="">
                           <span
                            v-show="showLessReport.indexOf(rowIndex) < 0"
                            class="text-xs italic text-gray-500 flex justify-center">
                            {{
                             $t("andNumberMoreElements", {
                              numb: value.split(",").length - 2,
                             })
                            }}
                           </span>
                           <div class="flex justify-center mt-2">
                            <button
                             @click="
                              showLessReport.indexOf(rowIndex) >= 0
                               ? showLess.splice(
                                  showLessReport.indexOf(rowIndex),
                                  1
                                 )
                               : showLess.push(rowIndex)
                             "
                             class="rounded-lg text-gray-700 bg-white px-1 py-0.5 text-xs">
                             {{
                              showLessReport.indexOf(rowIndex) < 0
                               ? $t("showMore")
                               : $t("showLess")
                             }}...
                            </button>
                           </div>
                          </div>
                         </span>
                        </span>
                        <span v-else-if="key === 'email'">
                         <span>
                          <!-- {{ value.split(",") }} -->
                          <div
                           v-if="showLessMail.indexOf(rowIndex) < 0"
                           class="space-y-1">
                           <span
                            v-for="mail in value.split(',').slice(0, 2)"
                            :key="mail"
                            class="flex items-center justify-between px-2 rounded-full bg-gray-200 py-1 text-xs font-medium text-gray-700">
                            <span class="truncate" :title="mail">
                             {{ mail }}
                            </span>
                           </span>
                          </div>
                          <div v-else class="space-y-1">
                           <span
                            v-for="mail in value.split(',')"
                            :key="mail"
                            class="flex items-center justify-between px-2 rounded-full bg-gray-200 py-1 text-xs font-medium text-gray-700">
                            <span class="truncate" :title="mail">
                             {{ mail }}
                            </span>
                           </span>
                          </div>
                          <div v-show="value.split(',').length > 2" class="">
                           <span
                            v-show="showLessMail.indexOf(rowIndex) < 0"
                            class="text-xs italic text-gray-500 flex justify-center">
                            {{
                             $t("andNumberMoreElements", {
                              numb: value.split(",").length - 2,
                             })
                            }}
                           </span>
                           <div class="flex justify-center mt-2">
                            <button
                             @click="
                              showLessMail.indexOf(rowIndex) >= 0
                               ? showLessMail.splice(
                                  showLessMail.indexOf(rowIndex),
                                  1
                                 )
                               : showLessMail.push(rowIndex)
                             "
                             class="rounded-lg text-gray-700 bg-white px-1 py-0.5 text-xs">
                             {{
                              showLessMail.indexOf(rowIndex) < 0
                               ? $t("showMore")
                               : $t("showLess")
                             }}...
                            </button>
                           </div>
                          </div>
                         </span>
                        </span>
                        <span v-else>
                         {{ $t(value) }}
                        </span>
                       </span>
                       <span v-else>
                        <svg
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20"
                         fill="currentColor"
                         class="w-5 h-5">
                         <path
                          d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
                        </svg>
                       </span>
                      </td>
                     </template>
                     <td
                      class="px-3 text-gray-500 whitespace-nowrap text-sm py-4 truncate max-w-sm">
                      <div class="flex items-center">
                       <a
                        :href="`https://presentation.cx-engine.net/${row.report_type}/${row.id}`"
                        target="_blank"
                        :class="[
                         'relative -ml-px flex items-center bg-white px-2 py-1 text-sm font-semibold text-gray-700 hover:text-teal-600 ring-1 ring-inset ring-gray-300 hover:bg-teal-100 focus:z-10',
                        ]">
                        <svg
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20"
                         fill="currentColor"
                         class="w-5 h-5">
                         <path
                          d="M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                         <path
                          fill-rule="evenodd"
                          d="M.664 10.59a1.651 1.651 0 0 1 0-1.186A10.004 10.004 0 0 1 10 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0 1 10 17c-4.257 0-7.893-2.66-9.336-6.41ZM14 10a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
                          clip-rule="evenodd" />
                        </svg>
                       </a>
                       <button
                        @click.prevent="
                         deleteItem(
                          row.id,
                          $t('delete'),
                          $t('confirmationMessage'),
                          $t('confirm'),
                          $t('goBack'),
                          $t('successMsg')
                         )
                        "
                        type="button"
                        :class="[
                         'hover:bg-red-50 hover:text-red-500 hover:ring-inset hover:ring-1 hover:ring-red-500 flex items-center text-gray-700 relative -ml-px bg-white px-2 py-1 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10',
                        ]">
                        <svg
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20"
                         fill="currentColor"
                         class="w-5 h-5">
                         <path
                          fill-rule="evenodd"
                          d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                          clip-rule="evenodd" />
                        </svg>
                       </button>
                      </div>
                     </td>
                    </tr>
                   </slot>
                  </tbody>
                 </table>
                 <PaginationComponent
                  :current_page="dataReports?.current_page"
                  :first_page_url="dataReports?.first_page_url"
                  :from="dataReports?.from"
                  :last_page="dataReports?.last_page"
                  :last_page_url="dataReports?.last_page_url"
                  :links="dataReports?.links"
                  :next_page_url="dataReports?.next_page_url"
                  :per_page="dataReports?.per_page"
                  :to="dataReports?.to"
                  :total="dataReports?.total"
                  @action="$emit('action', $event)"
                  @previousPage="previousPage()"
                  @nextPage="nextPage()" />
                </div>
               </div>
              </div>
             </div>
            </div>
            <!-- <div v-else class="pt-20">
             <svg
              xmlns="http://www.w3.org/2000/svg"
              class="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
               stroke-linecap="round"
               stroke-linejoin="round"
               stroke-width="2"
               d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
             </svg>
             <h3 class="mt-2 text-lg font-medium text-gray-900">
              {{ $t("queueHasNoData") }}
             </h3>
            </div> -->
           </div>
           <!-- /End replace -->
          </div>
         </div>
        </DialogPanel>
       </TransitionChild>
      </div>
     </div>
    </div>
   </Dialog>
  </TransitionRoot>

  <TransitionRoot as="template" :show="alertList">
   <Dialog as="div" class="relative z-40" @close="alertList = false">
    <div class="fixed inset-0" />

    <div class="fixed inset-0 overflow-hidden">
     <div class="absolute inset-0 overflow-hidden">
      <div
       class="pointer-events-none fixed inset-y-0 right-0 top-12 flex max-w-full pl-10">
       <TransitionChild
        as="template"
        enter="transform transition ease-in-out duration-500 sm:duration-700"
        enter-from="translate-x-full"
        enter-to="translate-x-0"
        leave="transform transition ease-in-out duration-500 sm:duration-700"
        leave-from="translate-x-0"
        leave-to="translate-x-full">
        <DialogPanel class="pointer-events-auto w-screen max-w-3xl">
         <div
          class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
          <div class="px-4 sm:px-6">
           <div class="flex items-start justify-between">
            <DialogTitle class="w-full text-lg font-medium text-gray-900">
             <div
              class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mx-0">
              <h2 class="uppercase text-lg font-bold">MES ALERTES PROGRAMMEES</h2>
             </div>
             <!-- {{ $t("agentDetails", { agentNumber: agentNumber, agentName: agentName }) }} -->
            </DialogTitle>
            <div class="ml-3 flex h-7 items-center">
             <button
              type="button"
              class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
              @click="alertList = false">
              <span class="sr-only">Close panel</span>
              <XIcon class="h-6 w-6" aria-hidden="true" />
             </button>
            </div>
           </div>
          </div>
          <div class="relative mt-4 flex-1">
           <!-- Replace with your content -->
           <div class="absolute border-gray-200 bg-white w-full pb-2">
            <div class="flex flex-col mb-0">
             <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
              <div
               class="py-4 align-middle inline-block min-w-full sm:px-4 lg:px-4">
               <div
                class="shadow overflow-hidden border-b border-gray-200 rounded-md">
                <div
                 class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                 <table class="min-w-full divide-y divide-gray-300">
                  <thead class="bg-gray-50">
                   <tr>
                    <!-- Render the th element only if header.name is not 'id' -->
                    <template v-for="(header, index) in theadAlert">
                     <th
                      v-if="header.name !== 'id'"
                      :key="index"
                      scope="col"
                      :class="{
                       'pl-4 pr-3 sm:pl-6': index === 1,
                       'px-3': index !== 1,
                      }"
                      class="py-3.5 text-left text-sm font-semibold text-gray-900 capitalize">
                      <span
                       v-if="
                        header.name.includes('_') &&
                        !header.name.includes('pattern') &&
                        !header.name === 'start_at'
                       ">
                       {{ $t(header.name.split("_")[1]) }}
                      </span>
                      <span v-else-if="header.name === 'repeat'">
                       Planifié
                      </span>
                      <span v-else-if="header.name === 'repeat_pattern'">
                       Chaque
                      </span>
                      <span v-else>
                       {{ $t(header.name) }}
                      </span>
                     </th>
                    </template>
                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                     <span class="sr-only">Edit</span>
                    </th>
                   </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white truncate">
                   <slot
                    :row="row"
                    v-for="(row, rowIndex) in tbodyAlert"
                    :key="rowIndex">
                    <tr>
                     <!-- Render the td element only if the key is not 'id' -->
                     <template v-for="(value, key, index) in row">
                      <td
                       v-if="key !== 'id' && key !== 'start_at'"
                       :key="key"
                       :class="{
                        'pl-4 pr-3 sm:pl-6 font-medium text-gray-900':
                         index === 1,
                        'px-3 text-gray-500': index !== 1,
                       }"
                       class="whitespace-nowrap text-sm py-4 truncate max-w-sm">
                       <span class="truncate" v-if="value || key === 'start'">
                        <span v-if="key === 'start' || key === 'end'">
                         <span v-if="row['repeat'] == 1 && key === 'start'">
                          {{ $d(new Date(row["start_at"]), "shortText", "fr") }}
                         </span>
                         <span v-else>
                          {{ $d(new Date(row["start_at"]), "shortText", "fr") }}
                         </span>
                        </span>
                        <span v-else-if="key === 'repeat'">
                         <div v-if="value">
                          <span
                           class="px-2 py-1 bg-green-200 text-green-600 rounded-xl"
                           >Oui</span
                          >
                         </div>
                         <div v-else>
                          <span class="px-2 py-1 bg-blue-300 text-blue-600"
                           >Non</span
                          >
                         </div>
                        </span>
                        <span v-else>
                         {{ $t(value) }}
                        </span>
                       </span>
                       <span v-else>
                        <svg
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20"
                         fill="currentColor"
                         class="w-5 h-5">
                         <path
                          d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
                        </svg>
                       </span>
                      </td>
                     </template>
                     <td
                      class="px-6 py-6 whitespace-nowrap text-right text-sm font-medium flex justify-end items-center gap-2">
                      <button
                       @click.prevent="
                        deleteItemAlert(
                         row.id,
                         $t('delete'),
                         $t('confirmationMessage'),
                         $t('confirm'),
                         $t('goBack'),
                         $t('successMsg')
                        )
                       "
                       type="button"
                       :class="[
                        'hover:bg-red-50 hover:text-red-500 hover:ring-inset hover:ring-1 hover:ring-red-500 text-gray-700 relative -ml-px inline-flex items-center bg-white px-2 py-1 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10',
                       ]">
                       <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-5 h-5">
                        <path
                         fill-rule="evenodd"
                         d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                         clip-rule="evenodd" />
                       </svg>
                      </button>
                     </td>
                    </tr>
                   </slot>
                  </tbody>
                 </table>
                 <PaginationComponent
                  :current_page="dataAlerts.current_page"
                  :first_page_url="dataAlerts.first_page_url"
                  :from="dataAlerts.from"
                  :last_page="dataAlerts.last_page"
                  :last_page_url="dataAlerts.last_page_url"
                  :links="dataAlerts.links"
                  :next_page_url="dataAlerts.next_page_url"
                  :per_page="dataAlerts.per_page"
                  :to="dataAlerts.to"
                  :total="dataAlerts.total"
                  @action="$emit('action', $event)"
                  @previousPage="previousPageAlert()"
                  @nextPage="nextPageAlert()" />
                </div>
               </div>
              </div>
             </div>
            </div>
            <!-- <div v-else class="pt-20">
             <svg
              xmlns="http://www.w3.org/2000/svg"
              class="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
               stroke-linecap="round"
               stroke-linejoin="round"
               stroke-width="2"
               d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
             </svg>
             <h3 class="mt-2 text-lg font-medium text-gray-900">
              {{ $t("queueHasNoData") }}
             </h3>
            </div> -->
           </div>
           <!-- /End replace -->
          </div>
         </div>
        </DialogPanel>
       </TransitionChild>
      </div>
     </div>
    </div>
   </Dialog>
  </TransitionRoot>
  <!-- <div
   v-if="dataAnswerTime.length && labelAnswerTime.length && dataQueueTable.length > 0 && dataSeriesOrigin.length && dataSeriesOrigin[0] && dataSeriesOrigin[0].data && dataSeriesOriginTop.length && dataSeriesOriginTop[0] && dataSeriesOriginTop[0].data"
   class="lg:flex lg:gap-4 sm:space-y-4 lg:space-y-0"
  >
   <QueueAnswerTimeCard :series="dataAnswerTime" :labels="labelAnswerTime" />
   <OriginsCard :key="dataSeriesOrigin[0]" v-show="dataSeriesOrigin[0].data" :data="dataSeriesOrigin[0].data" @quickPeriod="quickPeriodOrigin = $event" :quickPeriod="quickPeriodOrigin" @selectPeriod="periodSelectorOrigin = $event" />
   <OriginsTopCard :key="dataSeriesOriginTop[0]" v-show="dataSeriesOriginTop[0].data" :data="dataSeriesOriginTop[0].data" @quickPeriod="quickPeriodOriginTop = $event" :quickPeriod="quickPeriodOriginTop" @selectPeriod="periodSelectorOriginTop = $event" />
  </div> -->
 </div>
 <PopupShareReportDashboard
  ref="popupShareReport"
  :timeOption="this.buttonSelected"
  :elementOption="this.urlType"
  :parseDateRange="parseDateRange"
  :periodSelector="this.periodSelector"
  :selectHostName="this.hostName"
  selectHostContext="0"
  :startDate="startDate"
  :endDate="endDate"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event), $event ? getReportList() : ''"
  @reportList="reportList = true" />
 <PopupShareReportDashboard
  ref="popupShareScheduleReport"
  :timeOption="this.buttonSelected"
  :elementOption="this.urlType"
  :parseDateRange="parseDateRange"
  :periodSelector="this.periodSelector"
  :selectHostName="this.hostName"
  selectHostContext="0"
  :startDate="startDate"
  :endDate="endDate"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event), $event ? getReportList() : ''"
  @reportList="reportList = true" />
 <PopupCreateAlert
  ref="popupCreateAlert"
  :timeOption="this.buttonSelected"
  :elementOption="this.urlType"
  :parseDateRange="parseDateRange"
  :periodSelector="this.periodSelector"
  :selectHostName="this.hostName"
  selectHostContext="0"
  :startDate="startDate"
  :endDate="endDate"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event), $event ? getAlertList() : ''"
  @alertList="alertList = true" />
 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
 <TutorialCard
  ref="tutorial"
  :timeOption="this.buttonSelected"
  :elementOption="this.urlType"
  :parseDateRange="parseDateRange"
  :periodSelector="this.periodSelector"
  :selectHostName="this.hostName"
  selectHostContext="0"
  :startDate="startDate"
  :endDate="endDate"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event), $event ? getAlertList() : ''"
  @alertList="alertList = true"></TutorialCard>
</template>

<script>
import TrendChartWaitTime from "../../components/PBX/TrendChartWaitTime.vue";
import TrendChartAnswered from "../../components/PBX/TrendChartAnswered.vue";
import NoData from "../../components/NoData.vue";
import PleaseSelectElement from "../../components/PleaseSelectElement.vue";
import PbxDashboardSelector from "../../components/PBX/PbxDashboardSelector.vue";
import AskConfirmationDialog from "../../components/AskConfirmationDialog.vue";
import PaginationComponent from "../../components/PaginationComponent.vue";
import PopupCreateAlert from "../../components/PBX/PopupCreateAlert.vue";
import PopupShareReportDashboard from "../../components/PBX/PopupShareReportDashboard.vue";
import TopUsersCard from "../../components/PBX/Dashboard/TopUsersCard.vue";
import TenSecondsCallsCard from "../../components/PBX/Dashboard/TenSecondsCallsCard.vue";
import TotalCallsCard from "../../components/PBX/Dashboard/TotalCallsCard.vue";
import QueueAnswerTimeCard from "../../components/PBX/Dashboard/QueueAnswerTimeCard.vue";
import TopQueuesCard from "../../components/PBX/Dashboard/TopQueuesCard.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import AnsweredCard from "../../components/PBX/Dashboard/AnsweredCard.vue";
import OriginsCard from "../../components/PBX/Dashboard/OriginsCard.vue";
import OriginsTopCard from "../../components/PBX/Dashboard/OriginsTopCard.vue";
import AvgAnswerTimeCard from "../../components/PBX/Dashboard/AvgAnswerTimeCard.vue";
import LostCallsCard from "../../components/PBX/Dashboard/LostCallsCard.vue";
import TutorialCard from "../../components/PBX/Dashboard/TutorialCard.vue";
import TimeSelector from "../../components/PBX/TimeSelector";
import {
 Dialog,
 DialogPanel,
 DialogTitle,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";

const elementTypes = {
 0: "extension",
 1: "world",
 2: "undefined (2)",
 4: "queue",
 5: "voicemail",
 6: "ivr",
 8: "undefined (8)",
 9: "undefined (9)",
 12: "undefined (12)",
 13: "external_number_no_success",
 14: "call_flow",
 999: "not provided",
};

const calendarOptions = [
 {
  name: "yesterday",
  value: "yesterday",
  icon: "calendar-day",
 },
 {
  name: "this week",
  value: "currentWeek",
  icon: "calendar-week",
 },
 {
  name: "last week",
  value: "lastWeek",
  icon: "calendar-week",
 },
 {
  name: "this month",
  value: "currentMonth",
  icon: "calendar",
 },
 {
  name: "last month",
  value: "lastMonth",
  icon: "calendar",
 },
 {
  name: "last 3 months",
  value: "last3Months",
  icon: "calendar",
 },
 {
  name: "last 6 months",
  value: "last6Months",
  icon: "calendar",
 },
 {
  name: "personnalisé",
  value: "custom",
  icon: "calendar",
 },
];

export default {
 props: ["urlType", "showSideNav"],
 components: {
  AnsweredCard,
  OriginsCard,
  OriginsTopCard,
  TopQueuesCard,
  QueueAnswerTimeCard,
  TotalCallsCard,
  AvgAnswerTimeCard,
  LostCallsCard,
  TenSecondsCallsCard,
  TopUsersCard,
  PopupShareReportDashboard,
  PopupCreateAlert,
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  PaginationComponent,
  AskConfirmationDialog,
  PbxDashboardSelector,
  PleaseSelectElement,
  NoData,
  TrendChartAnswered,
  TrendChartWaitTime,
  TimeSelector,
  TutorialCard,
 },
 data() {
  return {
   showLessReport: [],
   showLessMail: [],
   currentMonthName: "",
   lastMonthName: "",
   currentYear: "",

   totalCalls: null, // Current month's data
   carCalls: null, // Current month's data
   answeredCalls: null, // Current month's data
   unansweredCalls: null, // Current month's data
   averageWaitTime: null, // Current month's data

   // Previous month's data
   prevTotalCalls: null,
   prevCarCalls: null,
   prevAnsweredCalls: null,
   prevUnansweredCalls: null,
   prevAverageWaitTime: null,

   // Calculate trends
   answeredTrend: 0,
   unansweredTrend: 0,
   waitTimeTrend: 0,
   carTrend: 0,
   totalTrend: 0,

   totalCallsInboundPbx: 0,
   totalCallsInternalPbx: 0,
   totalCallsOutboundPbx: 0,
   talkingInboundPbx: 0,
   talkingInternalPbx: 0,
   talkingOutboundPbx: 0,
   totalCallsArrPbx: [0, 0, 0],
   totalCallsArrDurationPbx: [0, 0, 0],
   alertList: false,
   reportList: false,
   customDate: null,
   showDatePickers: false,
   period: "",
   calendarOptions,
   selectedPeriod: "",
   answerType: "hour",
   elementTypes,
   quickPeriodAnswered: "last 30 days",
   quickPeriodOrigin: "last 30 days",
   quickPeriodOriginTop: "last 30 days",
   defaultPeriod: 30,
   periodSelectorAnswered: null,
   periodSelectorOrigin: null,
   periodSelectorOriginTop: null,
   dataColumnChartMonthLabels: [],
   dataColumnChart: [],
   dataColumnChartCategorie: [],
   dataColumnChartMonth: [],
   dataColumnChartMonthWaiting: [],
   dataColumnChartMonthWaitingLabels: [],
   dataColumnChartHour: [],
   dataColumnChartHourCategorie: [],
   dataColumnChartHourOutbound: [],
   dataSeriesOrigin: [],
   dataSeriesOriginTop: [],
   dataColumnChartTopUsers: [],
   dataColumnChartTopUsersLabels: [],
   dataColumnChartOutboundHour: [],
   dataColumnChartOutboundHourCategorie: [],
   dataColumnChartOutbound: [],
   dataColumnChartCategorieOutbound: [],
   dataQueueTable: [],
   dataAnswerTime: [],
   labelAnswerTime: [],
   data: undefined,
   worstAnsweredRate: null,
   bestAnsweredRate: null,
   mostCalls: null,
   totalCalls: 0,
   totalCallsOutbound: 0,
   lostCalls: 0,
   lostCallsOutbound: 0,
   answered_15: 0,
   talking_10: 0,
   waiting: 0,
   waitingOutbound: 0,
   talkingOutbound: 0,
   talking: 0,
   answering: 0,
   answeringOutbound: 0,
   mostCallsHour: "",
   mostCallsHourOutbound: "",
   bestRateHour: "",
   bestRateHourOutbound: "",
   lowestDisuadedRateHour: "",
   lowestAbandonedRateHour: "",
   lowestRateHour: "",
   lowestRateHourOutbound: "",
   worstAnsweredRateOutbound: null,
   mostCallsOutbound: null,
   bestAnsweredRateOutbound: null,
   totalCallsArr: [0, 0],
   totalCallsArrDuration: [0, 0],
   startDate: null,
   endDate: null,
   thead: [],
   tbody: [],
   theadAlert: [],
   tbodyAlert: [],
   currentSort: "id",
   currentSortDir: "asc",
   dataReports: undefined,
   dataAlerts: undefined,
   page: 1,
   pageAlerts: 1,
   selectedPbxElements: [],
   showLess: true,
  };
 },
 methods: {
  selectPeriod(value) {
   this.selectedPeriod = value;
  },
  async setPeriod(val) {
   this.answeredCalls = null;
   this.unansweredCalls = null;
   this.averageWaitTime = null;
   this.prevAnsweredCalls = null;
   this.prevUnansweredCalls = null;
   this.prevAverageWaitTime = null;
   this.answeredTrend = 0;
   this.unansweredTrend = 0;
   this.waitTimeTrend = 0;

   this.totalCalls = null;
   this.totalCallsOutbound = null;
   this.lostCalls = null;
   this.lostCallsOutbound = null;
   this.answered_15 = null;
   this.talking_10 = null;
   this.waiting = null;
   this.waitingOutbound = null;
   this.talkingOutbound = null;
   this.talking = null;
   this.answering = null;
   this.answeringOutbound = null;
   this.mostCallsHour = null;
   this.mostCallsHourOutbound = null;
   this.bestRateHour = null;
   this.bestRateHourOutbound = null;
   this.lowestDisuadedRateHour = null;
   this.lowestAbandonedRateHour = null;
   this.lowestRateHour = null;
   this.lowestRateHourOutbound = null;
   this.totalCallsArr = [0, 0];
   this.totalCallsArrDuration = [0, 0];

   this.worstAnsweredRateOutbound = null;
   this.mostCallsOutbound = null;
   this.bestAnsweredRateOutbound = null;

   this.totalCallsInboundPbx = 0;
   this.totalCallsInternalPbx = 0;
   this.totalCallsOutboundPbx = 0;
   this.talkingInboundPbx = 0;
   this.talkingInternalPbx = 0;
   this.talkingOutboundPbx = 0;
   this.totalCallsArrPbx = [0, 0, 0];
   this.totalCallsArrDurationPbx = [0, 0, 0];

   this.dataColumnChart = [];
   this.dataColumnChartCategorie = [];
   this.dataColumnChartMonth = [];
   this.dataColumnChartMonthWaiting = [];
   this.dataColumnChartOutbound = [];
   this.dataColumnChartHour = [];
   this.dataColumnChartHourCategorie = [];
   this.dataSeriesOrigin = [];
   this.dataSeriesOriginTop = [];
   this.dataColumnChartTopUsers = [];
   this.dataColumnChartTopUsersLabels = [];
   this.dataQueueTable = [];
   this.dataAnswerTime = [];
   this.labelAnswerTime = [];

   this.dataColumnChartOutboundHour = [];
   this.dataColumnChartOutboundHourCategorie = [];
   this.dataColumnChartCategorieOutbound = [];

   this.totalCallsArr = [0, 0];
   this.totalCallsArrDuration = [0, 0];

   let period = "";
   if (
    this.$route.name === "DashboardExpertStatisticsTrend" ||
    this.$route.name === "DashboardTrend"
   ) {
    period = this.getLast12Months();
   } else {
    if (val === "currentWeek") {
     period = this.getCurrentWeek();
     this.customDate = null;
    } else if (val === "yesterday") {
     period = this.getYesterday();
     this.customDate = null;
    } else if (val === "day") {
     period = this.getCurrentDay();
     this.customDate = null;
    } else if (val === "lastWeek") {
     period = this.getLastWeek();
     this.customDate = null;
    } else if (val === "currentMonth") {
     period = this.getCurrentMonth();
     this.customDate = null;
    } else if (val === "lastMonth") {
     period = this.getLastMonth();
     this.customDate = null;
    } else if (val === "last3Months") {
     period = this.getLast3Months();
     this.customDate = null;
    } else if (val === "last6Months") {
     period = this.getLast6Months();
     this.customDate = null;
    } else {
     period = this.customDate;
    }
   }
   this.$store.dispatch("pbxPeriodSelected", val);
   this.$store.dispatch("pbxCustomDate", this.customDate);
   this.startDate =
    val === "custom"
     ? this.parseDateDayMonthYearHifen(period?.start)
     : period?.firstDay;
   this.endDate =
    val === "custom"
     ? this.parseDateDayMonthYearHifen(period?.end)
     : period?.lastDay;
   this.$emit("setPeriod", this.startDate, this.endDate);

   if (this.urlType ? this.pbxElements && this.pbxElements.length > 0 : true) {
    this.$emit("setLoading", true);
    if (
     this.$route.name === "DashboardExpertStatisticsTrend" ||
     this.$route.name === "DashboardTrend"
    ) {
     this.setTrendData()
      .then(() => this.calculateTrends())
      .finally(() => this.$emit("setLoading", false));
     //  this.getDataFromElementForColumnTrend()
     //   .then(() => this.getDataTopQueuesTrend())
     //   .then(() => this.calculateTrends())
     //   .finally(() => this.$emit("setLoading", false));
    } else {
     this.setInboundQueuesData()
      .then(() => this.setOutboundData())
      .then(() => this.getPbxData())
      .then(() => this.getUsersData())
      // .then(() => this.getDataTopQueues())
      // .then(() => this.getDataFromElementForColumnOutbound())
      .finally(() => this.$emit("setLoading", false));
    }
   }
  },
  async getTrendData() {
   if (this.hostName) {
    let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
     this.hostName
    }/trend-monthly?start_time=${this.pbxTimeSelected[0]}&end_time=${
     this.pbxTimeSelected[1]
    }`;
    try {
     const res = await axios.get(url, {
      headers: {
       Authorization: "Bearer " + localStorage.getItem("apiToken"),
      },
     });
     return res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },

  // async getPbxData(el, sentPeriod, report, dnType) {
  //  if (this.hostName) {
  //   let arrayOfElements = [];
  //   let stringOfElements = "";
  //   if (this.pbxElements) {
  //    this.pbxElements?.forEach((element, index) => {
  //     let elementValue = element;
  //     if (typeof elementValue === "object") {
  //      for (let index = 0; index < elementValue.length; index++) {
  //       const element = elementValue[index];
  //       arrayOfElements.push(element);
  //      }
  //     } else {
  //      arrayOfElements.push(element);
  //     }
  //    });

  //    arrayOfElements = [...new Set(arrayOfElements)];

  //    stringOfElements = String(arrayOfElements[0]);

  //    for (let index = 1; index < arrayOfElements.length; index++) {
  //     stringOfElements =
  //      stringOfElements + "," + String(arrayOfElements[index]);
  //    }
  //   }

  //   let date = new Date();
  //   let year = date.getFullYear();
  //   let month = date.getMonth();
  //   let day = date.getDate();
  //   let start = this.formatDateApiRequest(
  //    this.getFirstDayOfMonth(year, month + 1)
  //   );
  //   let end = this.formatDateApiRequest(date);
  //   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
  //    this.hostName
  //   }/${report}?start=${this.startDate ? this.startDate : start}&end=${
  //    this.endDate ? this.endDate : end
  //   }&dns=${this.pbxElements ? stringOfElements : "*"}&dn_type=${dnType}`;
  //   try {
  //    const res = await axios.get(url, {
  //     headers: {
  //      Authorization: "Bearer " + localStorage.getItem("apiToken"),
  //     },
  //    });
  //    return res.data;
  //   } catch (error) {
  //    this.errorHandling(error);
  //   }
  //  }
  // },
  async getPbxData() {
   let data = null;
   let date = new Date();
   let year = date.getFullYear();
   let month = date.getMonth();
   let day = date.getDate();
   let start = this.formatDateApiRequest(
    this.getFirstDayOfMonth(year, month + 1)
   );
   let end = this.formatDateApiRequest(date);
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/unique-calls-period?start_date=${
    this.startDate ? this.startDate : start
   }&end_date=${this.endDate ? this.endDate : end}&start_time=${
    this.pbxTimeSelected[0]
   }&end_time=${this.pbxTimeSelected[1]}`;
   if (this.hostName) {
    try {
     const res = await axios.get(url, {
      headers: {
       Authorization: "Bearer " + localStorage.getItem("apiToken"),
      },
     });
     data = await res.data;
     this.setPbxData(data);
     // this.setTopUsers(data);
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  async getUsersData() {
   let data = null;
   let date = new Date();
   let year = date.getFullYear();
   let month = date.getMonth();
   let day = date.getDate();
   let start = this.formatDateApiRequest(
    this.getFirstDayOfMonth(year, month + 1)
   );
   let end = this.formatDateApiRequest(date);

   let arrayOfElements = [];
   let stringOfElements = "";
   if (this.hostName) {
    if ((this.pbxElements && this.pbxElements?.length > 0) || !this.urlType) {
     this.pbxElements?.forEach((element, index) => {
      let elementValue = element;
      if (typeof elementValue === "object") {
       for (let index = 0; index < elementValue.length; index++) {
        const element = elementValue[index];
        arrayOfElements.push(element);
       }
      } else {
       arrayOfElements.push(element);
      }
     });

     arrayOfElements = [...new Set(arrayOfElements)];

     stringOfElements = String(arrayOfElements[0]);

     for (let index = 1; index < arrayOfElements.length; index++) {
      stringOfElements =
       stringOfElements + "," + String(arrayOfElements[index]);
     }

     let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
      this.hostName
     }/users-calls?start_date=${
      this.startDate ? this.startDate : start
     }&end_date=${this.endDate ? this.endDate : end}&start_time=${
      this.pbxTimeSelected[0]
     }&end_time=${this.pbxTimeSelected[1]}&dn=${
      this.pbxElements ? stringOfElements : ""
     }`;
     try {
      const res = await axios.get(url, {
       headers: {
        Authorization: "Bearer " + localStorage.getItem("apiToken"),
       },
      });
      data = await res.data;
      this.setTopUsers(data);
     } catch (error) {
      this.errorHandling(error);
     }
    }
   }
  },
  async getInboundCallsQueueData() {
   let data = null;
   let date = new Date();
   let year = date.getFullYear();
   let month = date.getMonth();
   let day = date.getDate();
   let start = this.formatDateApiRequest(
    this.getFirstDayOfMonth(year, month + 1)
   );

   let arrayOfElements = [];
   let stringOfElements = "";
   if (this.hostName) {
    if ((this.pbxElements && this.pbxElements?.length > 0) || !this.urlType) {
     this.pbxElements?.forEach((element, index) => {
      let elementValue = element;
      if (typeof elementValue === "object") {
       for (let index = 0; index < elementValue.length; index++) {
        const element = elementValue[index];
        arrayOfElements.push(element);
       }
      } else {
       arrayOfElements.push(element);
      }
     });

     arrayOfElements = [...new Set(arrayOfElements)];

     stringOfElements = String(arrayOfElements[0]);

     for (let index = 1; index < arrayOfElements.length; index++) {
      stringOfElements =
       stringOfElements + "," + String(arrayOfElements[index]);
     }

     let end = this.formatDateApiRequest(date);
     let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
      this.hostName
     }/inbound-calls/${
      this.urlType === "queue" || !this.urlType ? "queue" : "extension"
     }?start_date=${this.startDate ? this.startDate : start}&end_date=${
      this.endDate ? this.endDate : end
     }&start_time=${this.pbxTimeSelected[0]}&end_time=${
      this.pbxTimeSelected[1]
     }&dn=${this.pbxElements ? stringOfElements : ""}`;
     try {
      const res = await axios.get(url, {
       headers: {
        Authorization: "Bearer " + localStorage.getItem("apiToken"),
       },
      });
      return res.data;
     } catch (error) {
      this.errorHandling(error);
     }
    }
   }
  },
  async getOutboundCallsData() {
   let data = null;
   let date = new Date();
   let year = date.getFullYear();
   let month = date.getMonth();
   let day = date.getDate();
   let start = this.formatDateApiRequest(
    this.getFirstDayOfMonth(year, month + 1)
   );
   let end = this.formatDateApiRequest(date);
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/outbound-calls?start_date=${
    this.startDate ? this.startDate : start
   }&end_date=${this.endDate ? this.endDate : end}&start_time=${
    this.pbxTimeSelected[0]
   }&end_time=${this.pbxTimeSelected[1]}`;
   if (this.hostName) {
    try {
     const res = await axios.get(url, {
      headers: {
       Authorization: "Bearer " + localStorage.getItem("apiToken"),
      },
     });
     return res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  async setTrendData() {
   const data = await this.getTrendData();

   // Prepare arrays for the column and line chart (number of calls and answer rate)
   const callsData = [];
   const answerRateData = [];

   // Prepare array for the line chart (average waiting time)
   const avgWaitingTimeData = [];

   // Prepare labels for both charts
   const labels = [];

   // Loop through the data to populate the arrays
   data?.forEach(res => {
    // Column chart data: inbound_calls
    callsData.push(res.inbound_calls);

    // Line chart data: answer rate (percentage of answered calls)
    const answerRate = (res.answered_inbound_calls / res.inbound_calls) * 100;
    answerRateData.push(answerRate.toFixed(0)); // Rounded to 0 decimals

    // Line chart data for average waiting time
    avgWaitingTimeData.push(res.avg_waiting_duration_in_seconds);

    // Labels for the months
    labels.push(res.month.slice(0, 7));
   });

   // Set data for the column and line chart (number of calls and answer rate)
   this.dataColumnChartMonth = [
    {
     name: "Nombre d'appels",
     type: "column",
     data: callsData,
    },
    {
     name: "Taux de réponse",
     type: "line",
     data: answerRateData,
    },
   ];

   // Set data for the line chart (average waiting time)
   this.dataColumnChartMonthWaiting = [
    {
     name: "Temps d'attente moyen",
     type: "line",
     data: avgWaitingTimeData,
    },
   ];

   // Set labels for both charts
   this.dataColumnChartMonthLabels = labels;
   this.dataColumnChartMonthWaitingLabels = labels;

   // Optional: Set the average waiting time for the last month and the previous month for comparison
   this.averageWaitTime = avgWaitingTimeData[avgWaitingTimeData.length - 1]; // Latest month
   this.prevAverageWaitTime = avgWaitingTimeData[avgWaitingTimeData.length - 2]; // Previous month
   this.totalCalls = callsData[callsData.length - 1]; // Total
   this.prevTotalCalls = callsData[callsData.length - 2]; // Total;
   this.carCalls = answerRateData[answerRateData.length - 1];
   this.prevCarCalls = answerRateData[answerRateData.length - 2];
  },
  async setInboundQueuesData() {
   const data = await this.getInboundCallsQueueData();
   const groupedData = await this.groupCallsData(data);
   this.setInboundQueuesTotalValues(groupedData);
   this.setChartData(groupedData);
  },
  async setOutboundData() {
   const data = await this.getOutboundCallsData();
   const groupedData = await this.groupCallsDataOutbound(data);
   this.setOutboundCallsTotalValues(groupedData);
   this.setChartDataOutbound(groupedData);
  },
  async groupCallsDataOutbound(data) {
   // Arrays to hold grouped data
   const groupedByDay = {};
   const groupedByHour = {};

   // Iterate over each call record
   await data?.forEach(record => {
    const date = record.hour.split(" ")[0]; // Get the date part
    const hour = record.hour.split(" ")[1]; // Get the time part

    // Initialize day grouping
    if (!groupedByDay[date]) {
     groupedByDay[date] = {
      outbound_calls: 0,
      answered_outbound_calls: 0,
      total_talking_duration_in_seconds: 0,
     };
    }
    // Sum up the calls for the day
    groupedByDay[date].outbound_calls += Number(record.outbound_calls);
    groupedByDay[date].answered_outbound_calls += Number(
     record.answered_outbound_calls
    );
    groupedByDay[date].total_talking_duration_in_seconds += Number(
     record.total_talking_duration_in_seconds
    );

    // Initialize hour grouping
    if (!groupedByHour[hour]) {
     groupedByHour[hour] = {
      outbound_calls: 0,
      answered_outbound_calls: 0,
      total_talking_duration_in_seconds: 0,
     };
    }
    // Sum up the calls for the hour
    groupedByHour[hour].outbound_calls += Number(record.outbound_calls);
    groupedByHour[hour].answered_outbound_calls += Number(
     record.answered_outbound_calls
    );
    groupedByHour[hour].total_talking_duration_in_seconds += Number(
     record.total_talking_duration_in_seconds
    );
   });

   // Convert objects to arrays for easier use
   const groupedByDayArray = Object.entries(groupedByDay).map(
    ([date, values]) => ({
     date,
     ...values,
    })
   );

   const groupedByHourArray = Object.entries(groupedByHour).map(
    ([hour, values]) => ({
     hour,
     ...values,
    })
   );

   return {
    groupedByDay: groupedByDayArray,
    groupedByHour: groupedByHourArray,
   };
  },
  async groupCallsData(data) {
   // Arrays to hold grouped data
   const groupedByDay = {};
   const groupedByHour = {};

   // Iterate over each call record
   await data?.forEach(record => {
    const date = record.hour.split(" ")[0]; // Get the date part
    const hour = record.hour.split(" ")[1]; // Get the time part

    // Initialize day grouping
    if (!groupedByDay[date]) {
     groupedByDay[date] = {
      inbound_calls: 0,
      answered_inbound_calls: 0,
      total_talking_duration_in_seconds: 0,
      total_waiting_duration_in_seconds: 0,
      ten_seconds_calls: 0,
     };
    }
    // Sum up the calls for the day
    groupedByDay[date].inbound_calls += Number(record.inbound_calls);
    groupedByDay[date].answered_inbound_calls += Number(
     record.answered_inbound_calls
    );
    groupedByDay[date].total_talking_duration_in_seconds += Number(
     record.total_talking_duration_in_seconds
    );
    groupedByDay[date].total_waiting_duration_in_seconds += Number(
     record.total_waiting_duration_in_seconds
    );
    groupedByDay[date].ten_seconds_calls += Number(record.ten_seconds_calls);

    // Initialize hour grouping
    if (!groupedByHour[hour]) {
     groupedByHour[hour] = {
      inbound_calls: 0,
      answered_inbound_calls: 0,
      total_talking_duration_in_seconds: 0,
      total_waiting_duration_in_seconds: 0,
      ten_seconds_calls: 0,
     };
    }
    // Sum up the calls for the hour
    groupedByHour[hour].inbound_calls += Number(record.inbound_calls);
    groupedByHour[hour].answered_inbound_calls += Number(
     record.answered_inbound_calls
    );
    groupedByHour[hour].total_talking_duration_in_seconds += Number(
     record.total_talking_duration_in_seconds
    );
    groupedByHour[hour].total_waiting_duration_in_seconds += Number(
     record.total_waiting_duration_in_seconds
    );
    groupedByHour[hour].ten_seconds_calls += Number(record.ten_seconds_calls);
   });

   // Convert objects to arrays for easier use
   const groupedByDayArray = Object.entries(groupedByDay).map(
    ([date, values]) => ({
     date,
     ...values,
    })
   );

   const groupedByHourArray = Object.entries(groupedByHour).map(
    ([hour, values]) => ({
     hour,
     ...values,
    })
   );

   return {
    groupedByDay: groupedByDayArray,
    groupedByHour: groupedByHourArray,
   };
  },
  async setChartData(groupedData) {
   this.setHourChartData(groupedData.groupedByHour);
   this.setDayChartData(groupedData.groupedByDay);
  },
  async setChartDataOutbound(groupedData) {
   this.setHourChartDataOutbound(groupedData.groupedByHour);
   this.setDayChartDataOutbound(groupedData.groupedByDay);
  },
  setHourChartDataOutbound(hourData) {
   const startHour = parseInt(this.pbxTimeSelected[0].slice(0, 2));
   const endHour = parseInt(this.pbxTimeSelected[1].slice(0, 2)) - 1;
   const allHours = [];

   // Generate all hours from 07:00:00 to 16:00:00
   for (let i = startHour; i <= endHour; i++) {
    const hourString = `${String(i).padStart(2, "0")}:00:00`;
    allHours.push(hourString);
   }

   // Create a map for hour data
   const hourMap = {};
   hourData.forEach(item => {
    hourMap[item.hour] = item;
   });

   let categories = [];
   let hourChartSeries = [];

   // Prepare series and categories
   categories = allHours; // Set categories to all hours between 07:00:00 and 16:00:00
   hourChartSeries = [
    {
     name: "Répondus",
     data: allHours.map(hour =>
      hourMap[hour] ? Number(hourMap[hour].answered_outbound_calls) : 0
     ),
    },
    {
     name: "Non répondus",
     data: allHours.map(hour =>
      hourMap[hour]
       ? Number(hourMap[hour].outbound_calls) -
         Number(hourMap[hour].answered_outbound_calls)
       : 0
     ),
    },
   ];

   this.dataColumnChartOutboundHour = hourChartSeries;
   this.dataColumnChartOutboundHourCategorie = categories;
  },
  setHourChartData(hourData) {
   const startHour = parseInt(this.pbxTimeSelected[0].slice(0, 2));
   const endHour = parseInt(this.pbxTimeSelected[1].slice(0, 2)) - 1;
   const allHours = [];

   for (let i = startHour; i <= endHour; i++) {
    const hourString = `${String(i).padStart(2, "0")}:00:00`;
    allHours.push(hourString);
   }

   // Create a map for hour data
   const hourMap = {};
   hourData.forEach(item => {
    hourMap[item.hour] = item;
   });

   let categories = [];
   let hourChartSeries = [];

   // Prepare series and categories
   categories = allHours;
   hourChartSeries = [
    {
     name: "Répondus",
     data: allHours.map(hour =>
      hourMap[hour] ? Number(hourMap[hour].answered_inbound_calls) : 0
     ),
    },
    {
     name: "Non répondus",
     data: allHours.map(hour =>
      hourMap[hour]
       ? Number(hourMap[hour].inbound_calls) -
         Number(hourMap[hour].answered_inbound_calls)
       : 0
     ),
    },
   ];
   this.dataColumnChartHour = hourChartSeries;
   this.dataColumnChartHourCategorie = categories;
  },
  setDayChartDataOutbound(dayData) {
   // Generate complete date range based on the selected period
   const allDays = this.generateDateRange(this.startDate, this.endDate);
   let categories = [];
   let dayChartSeries = [];

   // Create a map for day data
   const dayMap = {};
   dayData.forEach(item => {
    dayMap[item?.date] = item;
   });

   // Prepare series and categories
   categories = allDays.map(day => day.format("YYYY-MM-DD"));

   dayChartSeries = [
    {
     name: "Répondus",
     data: allDays.map(day =>
      dayMap[day.format("YYYY-MM-DD")]
       ? Number(dayMap[day.format("YYYY-MM-DD")].answered_outbound_calls)
       : 0
     ),
    },
    {
     name: "Non répondus",
     data: allDays.map(day =>
      dayMap[day.format("YYYY-MM-DD")]
       ? Number(dayMap[day.format("YYYY-MM-DD")].outbound_calls) -
         Number(dayMap[day.format("YYYY-MM-DD")].answered_outbound_calls)
       : 0
     ),
    },
   ];

   this.dataColumnChartOutbound = dayChartSeries;
   this.dataColumnChartCategorieOutbound = categories;
  },
  setDayChartData(dayData) {
   // Generate complete date range
   const allDays = this.generateDateRange(this.startDate, this.endDate);
   let categories = [];
   let dayChartSeries = [];

   // Create a map for day data
   const dayMap = {};
   dayData.forEach(item => {
    dayMap[item?.date] = item;
   });

   // Prepare series and categories
   categories = allDays.map(day => day.format("YYYY-MM-DD"));
   dayChartSeries = [
    {
     name: "Répondus",
     data: allDays.map(day =>
      dayMap[day.format("YYYY-MM-DD")]
       ? Number(dayMap[day.format("YYYY-MM-DD")].answered_inbound_calls)
       : 0
     ),
    },
    {
     name: "Non répondus",
     data: allDays.map(day =>
      dayMap[day.format("YYYY-MM-DD")]
       ? Number(dayMap[day.format("YYYY-MM-DD")].inbound_calls) -
         Number(dayMap[day.format("YYYY-MM-DD")].answered_inbound_calls)
       : 0
     ),
    },
   ];
   this.dataColumnChart = dayChartSeries;
   this.dataColumnChartCategorie = categories;
  },
  setTopUsers(data) {
   // Sort the users by total_calls in descending order and get the top 10
   const topUsers = data
    .sort((a, b) => b.total_calls - a.total_calls)
    .slice(0, 10);

   // Generate labels (display names of the top users)
   const labels = topUsers.map(user => user.display_name);

   // Prepare the series data
   const series = [
    {
     name: "Reçus&sup1",
     data: topUsers.map(user => user.inbound_calls),
    },
    {
     name: "Emis&sup2",
     data: topUsers.map(user => user.outbound_calls),
    },
    {
     name: "Internes&sup3",
     data: topUsers.map(
      user => user.internal_calls_made + user.internal_calls_received
     ),
    },
   ];

   // Assign data to chart properties
   this.dataColumnChartTopUsers = series;
   this.dataColumnChartTopUsersLabels = labels;
  },

  setPbxData(data) {
   // Initialize variables to ensure they get values from the provided data
   let inboundCalls = 0,
    outboundCalls = 0,
    internalCalls = 0;
   let inboundDuration = 0,
    outboundDuration = 0,
    internalDuration = 0;

   // Iterate through the data array to extract the values based on 'call_way'
   data?.forEach(item => {
    switch (item.call_way) {
     case "inbound":
      inboundCalls = parseInt(item.unique_calls); // Total inbound calls
      inboundDuration = parseInt(item.total_duration_seconds); // Inbound call duration
      break;
     case "outbound":
      outboundCalls = parseInt(item.unique_calls); // Total outbound calls
      outboundDuration = parseInt(item.total_duration_seconds); // Outbound call duration
      break;
     case "internal":
      internalCalls = parseInt(item.unique_calls); // Total internal calls
      internalDuration = parseInt(item.total_duration_seconds); // Internal call duration
      break;
     // You can add more cases if needed (e.g., 'transfer') but they're not used in your current logic
     default:
      break;
    }
   });

   // Set the extracted data
   this.totalCallsInboundPbx = inboundCalls;
   this.totalCallsInternalPbx = internalCalls;
   this.totalCallsOutboundPbx = outboundCalls;
   this.talkingInboundPbx = inboundDuration;
   this.talkingInternalPbx = internalDuration;
   this.talkingOutboundPbx = outboundDuration;

   // Prepare arrays for calls and durations
   this.totalCallsArrPbx = [
    this.totalCallsInboundPbx,
    this.totalCallsOutboundPbx,
    this.totalCallsInternalPbx,
   ];

   this.totalCallsArrDurationPbx = [
    this.talkingInboundPbx,
    this.talkingOutboundPbx,
    this.talkingInternalPbx,
   ];
  },
  setInboundQueuesTotalValues(data) {
   let totalTenSecondsCalls = 0;
   let totalWaitingDurationInSeconds = 0;
   let totalTalkingDurationInSeconds = 0;
   let totalInboundCalls = 0;
   let totalAnsweredInboundCalls = 0;

   let hourWithWorstAnsweredRate = null;
   let worstAnsweredRate = Infinity;

   let hourWithBestAnsweredRate = null;
   let bestAnsweredRate = -Infinity;

   let hourWithMostCalls = null;
   let mostCalls = 0;

   // Loop through the data to calculate total values
   data?.groupedByHour?.forEach(entry => {
    // Add to total ten seconds calls and waiting duration
    totalTenSecondsCalls += entry.ten_seconds_calls;
    totalWaitingDurationInSeconds += entry.total_waiting_duration_in_seconds;
    totalTalkingDurationInSeconds += entry.total_talking_duration_in_seconds;
    totalInboundCalls += entry.inbound_calls;
    totalAnsweredInboundCalls += entry.answered_inbound_calls;

    // Calculate answered rate for the current hour
    const answeredRate =
     (entry.answered_inbound_calls / entry.inbound_calls) * 100;

    // Check for worst answered rate
    if (answeredRate < worstAnsweredRate) {
     worstAnsweredRate = answeredRate;
     hourWithWorstAnsweredRate = entry.hour;
    }

    // Check for best answered rate
    if (answeredRate > bestAnsweredRate) {
     bestAnsweredRate = answeredRate;
     hourWithBestAnsweredRate = entry.hour;
    }

    // Check for the hour with most calls
    if (entry.inbound_calls > mostCalls) {
     mostCalls = entry.inbound_calls;
     hourWithMostCalls = entry.hour;
    }
   });

   // Calculate the total number of unanswered calls
   const totalUnansweredCalls = totalInboundCalls - totalAnsweredInboundCalls;

   // Calculate average waiting duration
   const avgWaitingDurationInSeconds =
    totalWaitingDurationInSeconds / totalAnsweredInboundCalls;

   this.totalCalls = totalInboundCalls;
   this.lostCalls = totalUnansweredCalls;
   this.answered_15 = avgWaitingDurationInSeconds.toFixed(0);
   this.talking_10 = totalTenSecondsCalls;
   this.waiting = totalWaitingDurationInSeconds;
   this.talking = totalTalkingDurationInSeconds;
   this.mostCallsHour = hourWithMostCalls;
   this.bestRateHour = hourWithBestAnsweredRate;
   this.lowestRateHour = hourWithWorstAnsweredRate;
   this.worstAnsweredRate = worstAnsweredRate;
   this.bestAnsweredRate = bestAnsweredRate;
   this.mostCalls = mostCalls;
  },
  setOutboundCallsTotalValues(data) {
   let totalTalkingDurationInSeconds = 0;
   let totalOutboundCalls = 0;
   let totalAnsweredOutboundCalls = 0;

   let hourWithWorstAnsweredRate = null;
   let worstAnsweredRate = Infinity;

   let hourWithBestAnsweredRate = null;
   let bestAnsweredRate = -Infinity;

   let hourWithMostCalls = null;
   let mostCalls = 0;

   // Loop through the data to calculate total values
   data?.groupedByHour?.forEach(entry => {
    // Add to total ten seconds calls and waiting duration
    totalTalkingDurationInSeconds += entry.total_talking_duration_in_seconds;
    totalOutboundCalls += entry.outbound_calls;
    totalAnsweredOutboundCalls += entry.answered_outbound_calls;

    // Calculate answered rate for the current hour
    const answeredRate =
     (entry.answered_outbound_calls / entry.outbound_calls) * 100;

    // Check for worst answered rate
    if (answeredRate < worstAnsweredRate) {
     worstAnsweredRate = answeredRate;
     hourWithWorstAnsweredRate = entry.hour;
    }

    // Check for best answered rate
    if (answeredRate > bestAnsweredRate) {
     bestAnsweredRate = answeredRate;
     hourWithBestAnsweredRate = entry.hour;
    }

    // Check for the hour with most calls
    if (entry.outbound_calls > mostCalls) {
     mostCalls = entry.outbound_calls;
     hourWithMostCalls = entry.hour;
    }
   });

   // Calculate the total number of unanswered calls
   const totalUnansweredCalls = totalOutboundCalls - totalAnsweredOutboundCalls;

   this.totalCallsOutbound = totalOutboundCalls;
   this.lostCallsOutbound = totalUnansweredCalls;
   this.talkingOutbound = totalTalkingDurationInSeconds;
   this.mostCallsHourOutbound = hourWithMostCalls;
   this.bestRateHourOutbound = hourWithBestAnsweredRate;
   this.lowestRateHourOutbound = hourWithWorstAnsweredRate;
   this.worstAnsweredRateOutbound = worstAnsweredRate;
   this.mostCallsOutbound = mostCalls;
   this.bestAnsweredRateOutbound = bestAnsweredRate;
  },
  async getDataFromElementForColumnTrend(period) {
   this.$store.dispatch("pbxElementType", this.urlType);

   let res = undefined;

   let labels = [];

   let obj = {};
   let callsData = [];
   let objCons = {};
   let dataObjAns = {};
   let dataObjAnsCons = {};
   let dataObjCarCons = {};
   let dataObjTotalCons = {};
   let dataObjUn = {};
   let dataObjUnCons = {};

   res = await this.getPbxData("*", period, "answered", 4);

   if (res) {
    let groupedDataByMonthCons = {};

    Object.keys(res["daily*"]).forEach(key => {
     let [date, , , metric] = key.split(":");
     let month = date.slice(0, 6); // Extract the month from the date (YYYYMM)

     if (!groupedDataByMonthCons[month]) {
      groupedDataByMonthCons[month] = { calls: 0, answered: 0 };
     }

     // Increment the relevant metric for this month
     if (metric === "calls") {
      groupedDataByMonthCons[month].calls += parseInt(res["daily*"][key]);
     }

     if (metric === "answered") {
      groupedDataByMonthCons[month].answered += parseInt(res["daily*"][key]);
     }
    });

    // Prepare the data for the column chart
    let months = Object.keys(groupedDataByMonthCons);

    months.sort(); // Sort months in chronological order

    obj = {};
    callsData = [];
    objCons = {};
    dataObjAns = {};
    dataObjAnsCons = {};
    dataObjTotalCons = {};
    dataObjUn = {};
    dataObjUnCons = {};
    dataObjCarCons = {};
    dataObjAns["data"] = [];
    dataObjUn["data"] = [];
    dataObjTotalCons["data"] = [];
    dataObjAnsCons["data"] = [];
    dataObjUnCons["data"] = [];
    dataObjCarCons["data"] = [];
    // obj["labels"] = [];
    // objCons["labels"] = [];
    months.forEach((month, monthIdx) => {
     dataObjTotalCons["name"] = "Appels";
     dataObjTotalCons["type"] = "column";
     dataObjTotalCons["data"].splice(
      monthIdx,
      1,
      groupedDataByMonthCons[month].calls
     );

     dataObjAnsCons["name"] = "Répondus";
     dataObjAnsCons["type"] = "column";
     dataObjAnsCons["data"].splice(
      monthIdx,
      1,
      groupedDataByMonthCons[month].answered
     );

     dataObjUnCons["name"] = "Non répondus";
     dataObjUnCons["type"] = "column";
     dataObjUnCons["data"].splice(
      monthIdx,
      1,
      groupedDataByMonthCons[month].calls -
       groupedDataByMonthCons[month].answered
     );

     dataObjCarCons["name"] = "Taux de réponse";
     dataObjCarCons["type"] = "line";
     dataObjCarCons["data"].splice(
      monthIdx,
      1,
      (
       (groupedDataByMonthCons[month].answered /
        groupedDataByMonthCons[month].calls) *
       100
      ).toFixed(0)
     );

     labels.splice(monthIdx, 1, month);
    });
    objCons["data"] = [dataObjTotalCons, dataObjCarCons];
    callsData = [
     dataObjTotalCons,
     dataObjAnsCons,
     dataObjUnCons,
     dataObjCarCons,
    ];
    this.dataColumnChartMonth[0] = objCons;
   }

   this.dataColumnChartMonthLabels = labels;

   this.totalCalls = callsData[0]["data"][11];
   this.prevTotalCalls = callsData[0]["data"][10];
   this.carCalls = callsData[3]["data"][11];
   this.prevCarCalls = callsData[3]["data"][10];
   this.lostCalls = callsData[2]["data"][11];
   this.answeredCalls = callsData[1]["data"][11];
   this.prevAnsweredCalls = callsData[1]["data"][10];
   this.unansweredCalls = callsData[2]["data"][11];
   this.prevUnansweredCalls = callsData[2]["data"][10];
  },

  setAnsweredUnansweredOutboundByHours(res) {
   let obj = {};
   let dataObjUn = {};
   let dataObjAns = {};
   let data = res["sum_hourly*"];
   let keys = Object.keys(data);
   let labels = [];
   let filledArrayDataAns = [];
   let filledArrayDataUns = [];
   keys.forEach(element => {
    let key = element.split(":");
    let time = key[0].slice(-2);
    if (parseInt(time) >= 0 && labels.indexOf(time) < 0) {
     labels.push(time);
    }
   });
   let sortedLabels = labels.sort((a, b) => parseInt(a) - parseInt(b));
   sortedLabels.forEach((label, index) => {
    keys.forEach(element => {
     let key = element.split(":");
     let time = key[0].slice(-2);
     let dataType = key[3];
     let action = key[4];
     if (parseInt(time) == parseInt(label)) {
      if (dataType === "calls") {
       if (action === "answered") {
        filledArrayDataAns.splice(index, 0, parseInt(data[element]));
        filledArrayDataUns.splice(
         index,
         0,
         parseInt(data[element.replace("answered", "calls")]) -
          parseInt(data[element]) >=
          0
          ? parseInt(data[element.replace("answered", "calls")]) -
             parseInt(data[element])
          : 0
        );
       }
      }
     }
    });
   });
   dataObjAns["name"] = "Répondus";
   dataObjAns["data"] = [];
   dataObjUn["name"] = "Non répondus";
   dataObjUn["data"] = [];
   obj["data"] = [];
   obj["labels"] = sortedLabels;
   //  let filledArrayDataAns = new Array(labels.length).fill(0);
   //  let filledArrayDataUns = new Array(labels.length).fill(0);
   dataObjAns["data"] = filledArrayDataAns;
   dataObjUn["data"] = filledArrayDataUns;
   obj["data"].splice(0, 1, dataObjAns);
   obj["data"].splice(1, 1, dataObjUn);

   this.dataColumnChartHourOutbound[0] = obj;
  },
  async getDataFromElementForColumn(period) {
   this.$store.dispatch("pbxElementType", this.urlType);

   let res = undefined;

   let obj = {};
   let dataObjAns = {};
   let dataObjUn = {};

   res = await this.getPbxData("*", period, "answered", 4);

   if (res) {
    let keys = [];

    keys = Object.keys(res["daily*"]);

    let days = [];
    days = res.days;

    let formatedDays = [];

    days.forEach(day => {
     formatedDays.push(this.formatDateApiRequest(day));
    });

    if (keys.length > 0) {
     obj = {};
     dataObjUn = {};
     dataObjAns = {};
     dataObjAns["name"] = "Répondus";
     dataObjAns["data"] = [];
     dataObjUn["name"] = "Non répondus";
     dataObjUn["data"] = [];
     obj["data"] = [];
     obj["labels"] = [];

     let filledArrayDataAns = new Array(days.length).fill(0);
     let filledArrayDataUns = new Array(days.length).fill(0);

     keys.forEach((element, index) => {
      let splitElement = element.split(":");
      let requestDay = splitElement[0];
      if (splitElement[2] === "*") {
       if (splitElement[3] === "answered") {
        for (let index = 0; index < formatedDays.length; index++) {
         const elementDay = formatedDays[index];
         if (elementDay === requestDay) {
          filledArrayDataAns.splice(
           index,
           1,
           parseInt(res["daily*"][element]) <=
            parseInt(res["daily*"][element.replace("answered", "calls")])
            ? parseInt(res["daily*"][element])
            : parseInt(res["daily*"][element.replace("answered", "calls")])
          );
         }
        }
        dataObjAns["name"] = "Répondus";
        dataObjAns["data"] = filledArrayDataAns;
       }

       if (splitElement[3] === "calls") {
        for (let index = 0; index < formatedDays.length; index++) {
         const elementDay = formatedDays[index];
         if (elementDay === requestDay) {
          filledArrayDataUns.splice(
           index,
           1,
           parseInt(res["daily*"][element]) -
            (parseInt(res["daily*"][element.replace("calls", "answered")])
             ? parseInt(res["daily*"][element.replace("calls", "answered")]) <=
               parseInt(res["daily*"][element])
               ? parseInt(res["daily*"][element.replace("calls", "answered")])
               : parseInt(res["daily*"][element])
             : 0)
          );
         }
        }
        dataObjUn["name"] = "Non répondus";
        dataObjUn["data"] = filledArrayDataUns;
       }
      } else {
       // in case there is key but no data for element
      }
     });

     obj["labels"] = days;

     obj["data"].splice(0, 1, dataObjAns);
     obj["data"].splice(1, 1, dataObjUn);

     this.dataColumnChart[0] = obj;
    } else {
     obj = {};

     obj["labels"] = [];
     obj["data"] = [];

     this.dataColumnChart[0] = obj;
    }
   }
   this.totalCalls =
    this.getAnsweredNumb(this.dataColumnChart[0]["data"]) +
    this.getUnansweredNumb(this.dataColumnChart[0]["data"]);
   this.lostCalls = this.getUnansweredNumb(this.dataColumnChart[0]["data"]);
  },

  setAnsweredUnansweredByHour(labels, data) {
   let obj = {};
   let dataObjUn = {};
   let dataObjAns = {};
   dataObjAns["name"] = "Répondus";
   dataObjAns["data"] = [];
   dataObjUn["name"] = "Non répondus";
   dataObjUn["data"] = [];
   obj["data"] = [];
   obj["labels"] = labels;
   let filledArrayDataAns = new Array(labels.length).fill(0);
   let filledArrayDataUns = new Array(labels.length).fill(0);
   labels.forEach((element, index) => {
    if (data[`answered_${element}`]) {
     filledArrayDataAns.splice(index, 1, data[`answered_${element}`]);
    }
    let unanswered =
     data[`calls_${element}`] -
     (data[`answered_${element}`] ? data[`answered_${element}`] : 0);
    filledArrayDataUns.splice(index, 1, unanswered);
   });
   dataObjAns["data"] = filledArrayDataAns;
   dataObjUn["data"] = filledArrayDataUns;
   obj["data"].splice(0, 1, dataObjAns);
   obj["data"].splice(1, 1, dataObjUn);

   this.dataColumnChartHour[0] = obj;
  },
  async getReportList() {
   this.$emit("setLoading", true);
   //  let reportType = this.getReportType();
   let reportType = null;
   let routeName = this.$route.name;
   let elementType = this.urlType ? (this.urlType === "queue" ? 4 : 0) : "*";
   if (routeName === "ExpertStatisticsDIDReport") {
    elementType = 4;
    reportType = this.getReportType();
   }
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/reports?page=${this.page}&filter[repeat]=1`;
   //&filter[report_type]=${reportType}&filter[element_type]=${elementType}
   try {
    const res = await axios.get(url, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
    });
    this.dataReports = res.data;
    console.log("this.dataReports ==> ", this.dataReports);

    if (res.data && res.data.data.length > 0) {
     const keyArr = Object.keys(res.data.data[0]);
     const excludedValues = [
      // "name",
      "host_name",
      "created_at",
      "updated_at",
      // "element_type",
      // "repeat_pattern",
      // "report_type",
      // "dns",
      "repeat",
      "start",
      "end",
      "instant",
      // "email",
      "email_sender",
     ];
     const excludedValuesHead = [
      // "name",
      "host_name",
      "created_at",
      "updated_at",
      // "element_type",
      // "start_at",
      "start",
      "end",
      // "repeat_pattern",
      "repeat",
      "report_type",
      // "dns",
      "instant",
      // "email",
      "email_sender",
     ];
     this.thead = keyArr
      .filter(name => !excludedValuesHead.includes(name))
      .map(name => ({ name, sort: "name", isSort: false }));

     this.tbody = res.data.data.map(obj => {
      const filteredObj = Object.fromEntries(
       Object.entries(obj).filter(([key]) => !excludedValues.includes(key))
      );
      return filteredObj;
     });
    }
   } catch (error) {
    this.errorHandling(error);
   } finally {
   }
   this.$emit("setLoading", false);
  },
  async getAlertList() {
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/alerts?page=${this.page}`;
   try {
    const res = await axios.get(url, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
    });
    this.dataAlerts = res.data;
    if (res.data && res.data.data.length > 0) {
     const keyArr = Object.keys(res.data.data[0]);
     const excludedValues = [
      "host_name",
      "created_at",
      "updated_at",
      "element_type",
      // "repeat_pattern",
      "report_type",
      "dns",
      "instant",
      "repeat",
      "email",
      "stats_type",
      "stats_measurement_type",
      "stats_measurement_limit",
      "stats_measurement_value",
      "end",
      "email_sender",
     ];
     const excludedValuesHead = [
      "host_name",
      "created_at",
      "updated_at",
      "element_type",
      "start_at",
      // "repeat_pattern",
      "report_type",
      "dns",
      "instant",
      "repeat",
      "email",
      "stats_type",
      "stats_measurement_type",
      "stats_measurement_limit",
      "stats_measurement_value",
      "end",
      "email_sender",
     ];
     this.theadAlert = keyArr
      .filter(name => !excludedValuesHead.includes(name))
      .map(name => ({ name, sort: "name", isSort: false }));

     this.tbodyAlert = res.data.data.map(obj => {
      const filteredObj = Object.fromEntries(
       Object.entries(obj).filter(([key]) => !excludedValues.includes(key))
      );
      return filteredObj;
     });
    }
   } catch (error) {
    this.errorHandling(error);
   } finally {
   }
  },
  nextPage() {
   if (this.page < this.dataReports?.last_page) {
    this.page = parseInt(this.page) + 1;
   } else {
    this.page = this.page;
   }
   this.getReportList();
  },
  previousPage() {
   if (this.page > this.dataReports?.last_page) {
    this.page = parseInt(this.page) - 1;
   } else {
    this.page = this.page;
   }
   this.getReportList();
  },
  async deleteItem(
   id,
   title,
   confirmationMessage,
   confirmButton,
   goBack,
   successMsg
  ) {
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/reports/${id}`;
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .delete(url, {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
      },
     })
     .then(res => {
      if (res) {
       // this.sendMessage(successMsg);
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", successMsg);
       this.getReportList();
      }
     })
     .catch(error => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
  async deleteItemAlert(
   id,
   title,
   confirmationMessage,
   confirmButton,
   goBack,
   successMsg
  ) {
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/alerts/${id}`;
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .delete(url, {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
      },
     })
     .then(res => {
      if (res) {
       // this.sendMessage(successMsg);
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", successMsg);
       this.getAlertList();
      }
     })
     .catch(error => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
  elementRemoved(element) {
   this.selectedPbxElements = this.pbxElements;
   let splitedElement = element.includes("-") ? element.split("-") : element;
   let elementValue = element.includes("-") ? splitedElement[0] : element;
   elementValue = elementValue.replace(/\s+/g, "");
   if (this.selectedPbxElements.indexOf(elementValue) >= 0) {
    this.selectedPbxElements.splice(
     this.selectedPbxElements.indexOf(elementValue),
     1
    );
   }
   this.$store.dispatch("pbxElements", this.selectedPbxElements);
   this.setPeriod(this.selectedPeriod);
   //  this.$emit("action", this.selectedPbxElements);
  },
  setTimeSelected(event) {
   this.timeSelected = event;
  },
  changeTab(path) {
   this.$router.push(path).then(() => this.setPeriod(this.selectedPeriod));
  },
  nextPage() {
   if (parseInt(this.page) + 1 <= this.dataReports?.last_page) {
    this.page = this.page + 1;
   }
  },
  previousPage() {
   if (parseInt(this.page) - 1 >= 1) {
    // this.$router.push(`/expert-statistics/reports/${this.urlType}/${this.page - 1}`);
    this.page = this.page - 1;
   }
  },
  nextPageAlert() {
   if (parseInt(this.pageAlert) + 1 <= this.dataAlerts?.last_page) {
    this.pageAlert = this.pageAlert + 1;
   }
  },
  previousPageAlert() {
   if (parseInt(this.pageAlert) - 1 >= 1) {
    // this.$router.push(`/expert-statistics/reports/${this.urlType}/${this.pageAlert - 1}`);
    this.pageAlert = this.pageAlert - 1;
   }
  },
  parseDateRange(date) {
   if (date.length === 8) {
    let year = ("0" + date).slice(1, -4);
    let month = ("0" + date).slice(-4, -2);
    let day = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = day + "/" + month + "/" + year;
    return String(fullDay);
   } else if (date.length === 6) {
    let year = ("0" + date).slice(1, 5);
    let month = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = month + "/" + year;
    return String(fullDay);
   } else if (date.length === 17) {
    let fullDay = date.split("-");
    let firstYear = ("0" + fullDay[0]).slice(1, 5);
    let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
    let firstDay = ("0" + fullDay[0]).slice(-2);

    let firstFullDay = "";
    firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

    let lastYear = ("0" + fullDay[1]).slice(1, 5);
    let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
    let lastDay = ("0" + fullDay[1]).slice(-2);

    let lastFullDay = "";
    lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
    let finalDay = "";
    finalDay = firstFullDay + " - " + lastFullDay;
    return String(finalDay);
   } else {
    return date;
   }
  },
  calculateTrends() {
   // Calculate percentage change from previous month
   this.carTrend = (
    ((this.carCalls - this.prevCarCalls) / this.prevCarCalls) *
    100
   ).toFixed(1);
   this.totalTrend = (
    ((this.totalCalls - this.prevTotalCalls) / this.prevTotalCalls) *
    100
   ).toFixed(1);
   this.answeredTrend = (
    ((this.answeredCalls - this.prevAnsweredCalls) / this.prevAnsweredCalls) *
    100
   ).toFixed(1);
   this.unansweredTrend = (
    ((this.unansweredCalls - this.prevUnansweredCalls) /
     this.prevUnansweredCalls) *
    100
   ).toFixed(1);
   this.waitTimeTrend = (
    ((this.averageWaitTime - this.prevAverageWaitTime) /
     this.prevAverageWaitTime) *
    100
   ).toFixed(1);
  },
  setCurrentMonthAndYear() {
   // Create a new Date object
   const date = new Date();

   // Get the current year
   this.currentYear = date.getFullYear().toString().slice(-2); // '24' for 2024

   // Get the current month name
   const monthNames = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
   ];
   this.currentMonthName = monthNames[date.getMonth() - 1]; // Get month as name
   this.lastMonthName = monthNames[date.getMonth() - 2]; // Get
  },
 },
 mounted() {
  this.setCurrentMonthAndYear();
  if (
   localStorage.getItem("dashboard_tutorial") == 1 &&
   this.$route.name === "Dashboard"
  ) {
   this.$refs.tutorial.show();
  }
  if (this.pbxElementType !== this.urlType || !this.urlType) {
   this.$store.dispatch("groupSelected", false);
   this.$store.dispatch("groupSelectedName", []);
   this.$store.dispatch("pbxElements", null);
  }
  // this.getDataFromElementForColumn(
  //  this.periodSelectorAnswered
  //   ? this.periodSelectorAnswered
  //   : this.defaultPeriod
  // );
  // this.getDataFromElementOrigins(this.periodSelectorOrigin ? this.periodSelectorOrigin : this.defaultPeriod);
  // this.getDataFromElementOriginsTop(this.periodSelectorOriginTop ? this.periodSelectorOriginTop : this.defaultPeriod);
  // this.getDataTopQueues();
  if (this.pbxPeriodSelected) {
   if (this.pbxPeriodSelected === "custom" && this.pbxCustomDate) {
    this.customDate = this.pbxCustomDate;
   }
   this.selectedPeriod = this.pbxPeriodSelected;
  } else {
   this.selectedPeriod = "lastMonth";
  }
  this.getReportList();
  this.getAlertList();
 },
 created() {},
 computed: {
  ...mapGetters([
   "account",
   "pbxElements",
   "pbxTimeSelected",
   "hostName",
   "hostContext",
   "hostTimeZone",
   "pbxElementType",
   "pbxPeriodSelected",
   "pbxMap",
   "pbxCustomDate",
  ]),
 },
 watch: {
  urlType: function (val) {
   this.$store.dispatch("pbxElements", null);
   this.$store.dispatch("groupSelected", false);
   this.$store.dispatch("groupSelectedName", []);
   this.setPeriod(this.selectedPeriod);
   this.getReportList();
   this.getAlertList();
  },
  page: function (val) {
   this.getReportList();
  },
  selectedPeriod: function (val) {
   this.setPeriod(val);
  },
  customDate: function (val) {
   if (val) {
    if (this.selectedPeriod === "custom") {
     this.setPeriod("custom");
    } else {
     this.selectedPeriod = "custom";
    }
   }
  },
  periodSelectorAnswered: function (val) {
   //  this.getDataFromElementForColumn(val);
  },
  periodSelectorOrigin: function (val) {
   //  this.getDataFromElementOrigins(val);
  },
  periodSelectorOriginTop: function (val) {
   //  this.getDataFromElementOriginsTop(val);
  },
 },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
 transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
 opacity: 0;
}
.beta__logo {
 left: 22rem;
}
</style>
