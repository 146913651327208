<template>
 <div class="w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
  <div class="flex justify-between border-gray-200 border-b dark:border-gray-700 pb-3">
   <dl>
    <dd class="leading-none text-xl font-bold text-gray-900 dark:text-white">Statistics this month</dd>
    <dt class="font-normal text-md text-gray-500 dark:text-gray-400 pb-1">Queues answered percentage rate</dt>
   </dl>
   <!-- <div>
    <span class="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-1 rounded-md dark:bg-green-900 dark:text-green-300">
     <svg class="w-2.5 h-2.5 mr-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13V1m0 0L1 5m4-4 4 4" />
     </svg>
     Since last month 23.5%
    </span>
   </div> -->
  </div>

  <div class="py-5 w-full text-center">
   <span class="isolate inline-flex rounded-md shadow-sm w-full text-center">
    <button
     @click="sortQueue = 'best'"
     type="button"
     :class="[sortQueue === 'best' ? 'text-gray-200 bg-gray-500 ring-gray-200 hover:bg-gray-600' : 'text-gray-500 bg-gray-100 ring-gray-300 hover:bg-gray-50', 'relative w-full items-center text-center rounded-l-md px-2 py-2  ring-1 ring-inset  focus:z-10']"
    >
     <span class="text-center">Best</span>
    </button>
    <button
     @click="sortQueue = 'worst'"
     type="button"
     :class="[sortQueue === 'worst' ? 'text-gray-200 bg-gray-500 ring-gray-200 hover:bg-gray-600' : 'bg-gray-100 text-gray-500 ring-gray-300 hover:bg-gray-50', 'relative -ml-px w-full items-center text-center rounded-r-md  px-2 py-2  ring-1 ring-inset  focus:z-10']"
    >
     <span class="text-center">Worst</span>
    </button>
   </span>
  </div>

  <div class="pt-0 queue-list__height--max overflow-y-auto">
   <ul role="list" class="divide-y divide-gray-100">
    <li v-for="queue in dataSorted" :key="queue.queue" class="flex justify-between gap-x-6 py-4">
     <div class="flex min-w-0 gap-x-4">
      <div class="min-w-0 flex-auto">
       <p class="text-sm font-semibold leading-6 text-gray-900">{{ queue.name }}</p>
       <p class="mt-1 truncate text-xs leading-5 text-gray-500">{{ queue.queue }}</p>
      </div>
     </div>
     <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
      <p class="text-sm leading-6 text-gray-900">{{ queue.answered_rate }}% </p>
      <p class="mt-1 text-xs leading-5 text-gray-500">{{ queue.calls }} <span>calls</span></p>
     </div>
    </li>
   </ul>
  </div>
  <div class="items-center border-gray-200 border-t dark:border-gray-700 justify-end mt-2">
   <div class="flex justify-end items-center pt-5">
    <a href="/expert-statistics/full-report/queue" class="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600 hover:text-blue-700 dark:hover:text-blue-500 hover:bg-gray-100 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 px-3 py-2">
     Queue Report
     <svg class="w-2.5 h-2.5 ml-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
     </svg>
    </a>
   </div>
  </div>
 </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

const people = [
 {
  name: "Leslie Alexander",
  email: "leslie.alexander@example.com",
  role: "Co-Founder / CEO",
 },
 {
  name: "Michael Foster",
  email: "michael.foster@example.com",
  role: "Co-Founder / CTO",
 },
 {
  name: "Dries Vincent",
  email: "dries.vincent@example.com",
  role: "Business Relations",
 },
 {
  name: "Lindsay Walton",
  email: "lindsay.walton@example.com",
  role: "Front-end Developer",
 },
 {
  name: "Courtney Henry",
  email: "courtney.henry@example.com",
  role: "Designer",
 },
];

const periodOptions = [
 //  { title: "today", current: false, value: 0 },
 //  { title: "yesterday", current: true, value: 1 },
 { title: "last 7 days", current: false, value: 7 },
 { title: "last 30 days", current: false, value: 30 },
 //  { title: "last 90 days", current: false },
];

export default {
 props: ["data"],
 components: {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
 },
 data() {
  return {
   sortQueue: "best",
   people,
   periodValue: null,
   periodOptions,
   showList: false,
   series: [
    {
     name: "Answered",
     color: "#31C48D",
     data: ["1420", "1620", "1820", "1420", "1650", "2120"],
    },
    {
     name: "Unanswered",
     data: ["788", "810", "866", "788", "1100", "1200"],
     color: "#F05252",
    },
   ],
   chartOptions: {
    colors: ["#1E7889", "#E34B5F"],
    chart: {
     stacked: true,
     sparkline: {
      enabled: false,
     },
     type: "bar",
     width: "100%",
     height: 400,
     toolbar: {
      show: false,
     },
    },
    plotOptions: {
     bar: {
      horizontal: false,
      columnWidth: "80%",
      borderRadiusApplication: "end",
      borderRadius: 6,
      dataLabels: {
       position: "top",
      },
     },
    },
    legend: {
     show: true,
     position: "bottom",
    },
    dataLabels: {
     enabled: false,
    },
    stroke: {
     show: true,
     width: 0.5,
     colors: ["#fff"],
    },
    tooltip: {
     shared: true,
     intersect: false,
    },
    xaxis: {
     labels: {
      show: true,
      style: {
       fontFamily: "Inter, sans-serif",
       cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
      },
      formatter: function (value, timestamp, opts) {
       let finalValue = "";
       if (value.length > 0) {
        let newDate = new Date(value);
        let year = newDate.getFullYear();
        let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
        let day = ("0" + newDate.getDate()).slice(-2);
        let formated = String(day) + "/" + String(month) + "/" + String(year);
        return formated;
       }

       return finalValue;
      },
     },
     categories: this.categoriesForColumn ? this.categoriesForColumn : [],
     axisTicks: {
      show: true,
     },
     axisBorder: {
      show: true,
     },
    },
    yaxis: {
     labels: {
      show: false,
      style: {
       fontFamily: "Inter, sans-serif",
       cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
      },
     },
    },
    grid: {
     show: true,
     strokeDashArray: 4,
     padding: {
      left: 10,
      right: 10,
      top: -20,
     },
    },
   },
  };
 },
 mounted() {},
 watch: {
  periodValue: function (val) {
   this.$emit("selectPeriod", val);
  },
 },
 computed: {
  dataSorted() {
   if (this.sortQueue === "best") {
    return this.data.sort((a, b) => b.answered_rate - a.answered_rate).slice(0, 5);
   } else {
    return this.data.sort((a, b) => a.answered_rate - b.answered_rate).slice(0, 5);
   }
  },
 },
};
</script>

<style>
.queue-list__height--max {
 height: 28.45rem;
}
</style>
