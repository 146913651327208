<template>
 <div class="w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
  <div class="flex justify-between border-gray-200 border-b dark:border-gray-700 pb-3">
   <dl>
    <!-- <dd class="leading-none text-xl font-bold text-gray-900 dark:text-white">Statistics this month</dd> -->
    <dt class="font-normal text-md text-gray-500 dark:text-gray-400 pb-1">Queues average answer time (s)</dt>
   </dl>
   <!-- <div>
    <span class="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-1 rounded-md dark:bg-green-900 dark:text-green-300">
     <svg class="w-2.5 h-2.5 mr-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13V1m0 0L1 5m4-4 4 4" />
     </svg>
     Since last month 23.5%
    </span>
   </div> -->
  </div>

  <div id="chart" class="py-5">
   <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
  </div>
  <div class="items-center border-gray-200 border-t dark:border-gray-700 justify-end mt-3">
   <div class="flex justify-end items-center pt-5">
    <a href="/expert-statistics/full-report/queue" class="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600 hover:text-blue-700 dark:hover:text-blue-500 hover:bg-gray-100 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 px-3 py-2">
     Queue Report
     <svg class="w-2.5 h-2.5 ml-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
     </svg>
    </a>
   </div>
  </div>
 </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

const periodOptions = [
 //  { title: "today", current: false, value: 0 },
 //  { title: "yesterday", current: true, value: 1 },
 { title: "last 7 days", current: false, value: 7 },
 { title: "last 30 days", current: false, value: 30 },
 //  { title: "last 90 days", current: false },
];

export default {
 props: ["series", "labels"],
 components: {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
 },
 data() {
  return {
   sortQueue: "best",
   periodValue: null,
   periodOptions,
   showList: false,
   series: [
    {
     name: "Avg. answering time",
     data: this.series,
    },
   ],
   chartOptions: {
    chart: {
     type: "area",
     height: 350,
     zoom: {
      enabled: false,
     },
     toolbar: { show: false },
    },
    dataLabels: {
     enabled: false,
    },
    stroke: {
     curve: "straight",
    },
    labels: this.labels,
    xaxis: {
     labels: {
      show: false,
      formatter: function (value, timestamp, opts) {
       if (value && value.length > 0) {
        let year = value.slice(0, 4);
        let month = value.slice(4, 6);
        let day = value.slice(-2);
        let formated = String(day) + "/" + String(month) + "/" + String(year);
        return formated;
       }
      },
     },
    },
    yaxis: {
     opposite: true,
     show: false,
    },
    grid: {
     show: false,
    },
   },
  };
 },
 mounted() {},
 watch: {
  periodValue: function (val) {
   this.$emit("selectPeriod", val);
  },
 },
 computed: {},
};
</script>

<style>
.queue-list__height--max {
 height: 28.45rem;
}
</style>
