<template>
 <div class="bg-white shadow-md rounded-lg relative pb-4">
  <h2 class="text-center font-bold text-sm -mb-4">Durée d'attente moyenne</h2>
  <div class="flex justify-center items-center">
   <div id="chart">
    <apexchart
     type="radialBar"
     height="350"
     :options="chartOptions"
     :series="series"></apexchart>
   </div>
   <!-- <div class="items-center text-left">
    <div class="text-xs">
     <span>Durée d'attente moyenne</span>
    </div>
    <div class="flex text-lg font-bold">
     <span class="text-blue-600">{{ answered_15 }} sec</span>
    </div>
   </div> -->
  </div>
  <div
   class="flex justify-center items-center px-2 py-2 text-sm bg-blue-100 rounded-lg shadow-md text-blue-800 mx-2 h-1/4">
   <div v-if="urlType === 'extension'">
    En moyenne, les appels sont répondus au bout de
    <span class="font-bold"> {{ answered_15 }} secondes</span>.
    <!-- La durée
   d'attente la plus longue a été de
   <span class="font-bold"> 5 min 14 sec </span>.  -->
    La durée d'attente concerne uniquement les appels
    <span class="">répondus</span>.
   </div>
   <div v-else>
    En moyenne, les appels sont répondus au bout de
    <span class="font-bold"> {{ answered_15 }} secondes</span>.
    <!-- La durée
   d'attente la plus longue a été de
   <span class="font-bold"> 5 min 14 sec </span>.  -->
    La durée d'attente inclut le message de
    <span class="">pré-décroché</span> et concerne les appels
    <span class="">répondus</span>.
    <!-- et <span class="">non-répondus</span> -->
   </div>
  </div>
 </div>
</template>

<script>
export default {
 props: ["total", "answered_15", "urlType"],
 data() {
  return {
   series: [this.answered_15],
   chartOptions: {
    chart: {
     fontFamily: "Nunito, sans-serif",
     type: "radialBar",
     offsetY: 0,
    },
    plotOptions: {
     radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
       background: "#e7e7e7",
       strokeWidth: "97%",
       margin: 5, // margin is in pixels
       dropShadow: {
        enabled: true,
        top: 2,
        left: 0,
        color: "#999",
        opacity: 1,
        blur: 2,
       },
      },
      dataLabels: {
       value: {
        offsetY: -2,
        fontSize: "20px",
        fontFamily: "Nunito, sans-serif",
        color: "#0000FF",
        formatter: function (val) {
         return val + " sec";
        },
       },
      },
     },
    },
    fill: {
     type: "gradient",
     gradient: {
      shade: "dark",
      shadeIntensity: 0.15,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 65, 91],
     },
    },
    stroke: {
     dashArray: 2,
    },
    labels: [""],
   },
  };
 },
 methods: {},
 mounted() {
  this.$emit("setLoading", false);
 },
 updated() {
  this.$emit("setLoading", false);
 },
};
</script>

<style></style>
